.items-start {
  align-items: flex-start;
}
group:hover .group-hover\:opacity-100 {
  opacity: 1;
}
.pointer-events-none {
  pointer-events: none;
}
.hover\:text-red-600:hover {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity)) /* #dc2626 */;
}
.hover\:text-\[\#09e9e9\]:hover {
  --tw-text-opacity: 1;
  color: rgb(9 233 233 / var(--tw-text-opacity)) /* #09e9e9 */;
}
.py-\[7px\] {
  padding-top: 7px;
  padding-bottom: 7px;
}

.w-\[55\%\] {
  width: 55%;
}
.w-\[20\%\] {
  width: 20%;
}
.h-\[487px\] {
  height: 487px;
}
.h-\[230px\] {
  height: 230px;
}
.mt-\[-10px\] {
  margin-top: -10px;
}

.h-\[27\%\] {
  height: 27%;
}
.gap-\[20px\] {
  gap: 20px;
}
.pb-\[20px\] {
  padding-bottom: 20px;
}
.text-\[22px\] {
  font-size: 22px;
}
.mt-\[8px\] {
  margin-top: 8px;
}
.mb-\[18px\] {
  margin-bottom: 18px;
}
.w-\[268px\] {
  width: 268px;
}
.mt-\[0\.7vw\] {
  margin-top: 0.7vw;
}
.mt-0\.5 {
  margin-top: 0.125rem /* 2px */;
}
.mb-2\.5 {
  margin-bottom: 0.625rem /* 10px */;
}
.no-underline {
  text-decoration-line: none;
}
.mt-\[10vh\] {
  margin-top: 10vh;
}
.w-\[60\%\] {
  width: 60%;
}
.w-\[65\%\] {
  width: 65%;
}
.w-\[99px\] {
  width: 99px;
}
.top-1\/2 {
  top: 50%;
}
.px-4 {
  padding-left: 1rem /* 16px */;
  padding-right: 1rem /* 16px */;
}
.px-2\.5 {
  padding-left: 0.625rem /* 10px */;
  padding-right: 0.625rem /* 10px */;
}
.align-baseline {
  vertical-align: baseline;
}
.w-\[219px\] {
  width: 219px;
}
.mt-\[16px\] {
  margin-top: 16px;
}
.mt-\[12px\] {
  margin-top: 12px;
}
.w-\[95\%\] {
  width: 95%;
}
.w-\[94\%\] {
  width: 94%;
}
.w-\[40\%\] {
  width: 40%;
}
.h-\[441px\] {
  height: 441px;
}
.h-\[310px\] {
  height: 310px;
}
.h-\[275px\] {
  height: 275px;
}
.pb-2\.5 {
  padding-bottom: 0.625rem /* 10px */;
}
.px-\[23px\] {
  padding-left: 23px;
  padding-right: 23px;
}
.px-1\.5 {
  padding-left: 0.375rem /* 6px */;
  padding-right: 0.375rem /* 6px */;
}
.mt-\[3px\] {
  margin-top: 3px;
}
.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
    scaleY(var(--tw-scale-y));
}
.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
    scaleY(var(--tw-scale-y));
}
.ml-9 {
  margin-left: 2.25rem /* 36px */;
}
.p-0\.5 {
  padding: 0.125rem /* 2px */;
}
.w-\[55\%\] {
  width: 55%;
}
.w-3\/4 {
  width: 75%;
}
.h-\[520px\] {
  height: 520px;
}
.max-h-\[520px\] {
  max-height: 520px;
}
.h-\[549px\] {
  height: 549px;
}
.w-\[8\%\] {
  width: 8%;
}

.w-\[92\%\] {
  width: 92%;
}

.max-h-\[450px\] {
  max-height: 450px;
}

.rounded-\[10px\] {
  border-radius: 10px;
}

.pl-2\.5 {
  padding-left: 0.625rem /* 10px */;
}

.pt-2\.5 {
  padding-top: 0.625rem /* 10px */;
}

.pr-4 {
  padding-right: 1rem /* 16px */;
}
.h-\[253px\] {
  height: 253px;
}

.w-\[760px\] {
  width: 760px;
}

.w-\[82px\] {
  width: 82px;
}

.mt-16 {
  margin-top: 4rem /* 64px */;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.rounded-\[20px\] {
  border-radius: 20px;
}

.w-\[80\%\] {
  width: 80%;
}

.mt-\[15px\] {
  margin-top: 15px;
}

.py-\[9px\] {
  padding-top: 9px;
  padding-bottom: 9px;
}
.top-\[50px\] {
  top: 50px;
}
.pr-5 {
  padding-right: 1.25rem /* 20px */;
}

.aspect-\[382\/597\] {
  aspect-ratio: 382/597;
}

.aspect-\[764\/597\] {
  aspect-ratio: 764/597;
}

.aspect-\[1056\/597\] {
  aspect-ratio: 1056/597;
}

.aspect-\[1920\/574\] {
  aspect-ratio: 1920/574;
}

.aspect-\[1920\/802\] {
  aspect-ratio: 1920/802;
}

.hover\:w-full:hover {
  width: 100%;
}

.h-\[60px\] {
  height: 60px;
}
.px-\[5\%\] {
  padding-left: 5%;
  padding-right: 5%;
}

.mt-\[31px\] {
  margin-top: 31px;
}
.mt-7 {
  margin-top: 1.75rem /* 28px */;
}
.gap-y-4 {
  row-gap: 1rem /* 16px */;
}
.h-\[140px\] {
  height: 140px;
}
.px-\[8px\] {
  padding-left: 8px;
  padding-right: 8px;
}
.h-14 {
  height: 3.5rem /* 56px */;
}
.mx-5 {
  margin-left: 1.25rem /* 20px */;
  margin-right: 1.25rem /* 20px */;
}
.z-40 {
  z-index: 40;
}
.hover\:scale-101:hover {
  --tw-scale-x: 1.01;
  --tw-scale-y: 1.01;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
    scaleY(var(--tw-scale-y));
}
.left-1\/4 {
  left: 25%;
}
.bottom-\[-130px\] {
  bottom: -130px;
}
.mb-\[130px\] {
  margin-bottom: 130px;
}
.pb-8 {
  padding-bottom: 2rem /* 32px */;
}

.top-\[60\%\] {
  top: 60%;
}
.aspect-square {
  aspect-ratio: 1 / 1;
}
.bottom-\[45px\] {
  bottom: 45px;
}

.-left-1\/2 {
  left: -50%;
}

.bottom-full {
  bottom: 100%;
}

.opacity-60 {
  opacity: 0.6;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.py-1\.5 {
  padding-top: 0.375rem /* 6px */;
  padding-bottom: 0.375rem /* 6px */;
}

.mr-20 {
  margin-right: 5rem /* 80px */;
}
.w-\[70\%\] {
  width: 70%;
}

.pr-7 {
  padding-right: 1.75rem /* 28px */;
}

.pl-4 {
  padding-left: 1rem /* 16px */;
}

.right-\[100px\] {
  right: 100px;
}

.px-5 {
  padding-left: 1.25rem /* 20px */;
  padding-right: 1.25rem /* 20px */;
}
.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.h-8 {
  height: 2rem /* 32px */;
}
.m-4 {
  margin: 1rem /* 16px */;
}
.bg-\[\#0c121a\] {
  --tw-bg-opacity: 1;
  background-color: rgb(12 18 26 / var(--tw-bg-opacity));
}
.bg-inherit {
  background-color: inherit;
}
.mr-auto {
  margin-right: auto;
}
.gap-x-6 {
  column-gap: 1.5rem /* 24px */;
}
.gap-x-5 {
  column-gap: 1.25rem /* 20px */;
}
.mr-10 {
  margin-right: 2.5rem /* 40px */;
}
.-right-20 {
  right: -5rem /* -80px */;
}
.text-\[\#c4c4c4\] {
  --tw-text-opacity: 1;
  color: rgb(196 196 196 / var(--tw-text-opacity));
}
.gap-x-10 {
  column-gap: 2.5rem /* 40px */;
}
.bottom-\[5\%\] {
  bottom: 5%;
}
.w-9 {
  width: 2.25rem /* 36px */;
}
.h-9 {
  height: 2.25rem /* 36px */;
}
.text-\[\#0abcff\] {
  --tw-text-opacity: 1;
  color: rgb(10 188 255 / var(--tw-text-opacity));
}
.space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3rem * var(--tw-space-y-reverse));
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.z-99 {
  z-index: 99;
}
.h-screen {
  height: 100vh;
}
.w-\[146px\] {
  width: 146px;
}
.py-\[0\.25rem\] {
  padding-top: 0.25rem /* 4px */;
  padding-bottom: 0.25rem /* 4px */;
}
.px-\[0\.25rem\] {
  padding-left: 0.25rem /* 4px */;
  padding-right: 0.25rem /* 4px */;
}
.py-\[0\.5rem\] {
  padding-top: 0.5rem /* 4px */;
  padding-bottom: 0.5rem /* 4px */;
}
.px-\[0\.5rem\] {
  padding-left: 0.5rem /* 4px */;
  padding-right: 0.5rem /* 4px */;
}
.py-\[0\.75rem\] {
  padding-top: 0.75rem /* 4px */;
  padding-bottom: 0.75rem /* 4px */;
}
.w-\[63\.38\%\] {
  width: 63.38%;
}
.w-\[44\%\] {
  width: 44%;
}
.px-\[0\.75rem\] {
  padding-left: 0.75rem /* 4px */;
  padding-right: 0.75rem /* 4px */;
}
.px-\[1rem\] {
  padding-left: 1rem /* 4px */;
  padding-right: 1rem /* 4px */;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.p-3 {
  padding: 0.75rem /* 12px */;
}
.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.p-\[16px\] {
  padding: 16px;
}
.w-\[216px\] {
  width: 216px;
}
.px-\[16px\] {
  padding-left: 16px;
  padding-right: 16px;
}
.aspect-\[0\.781690141\] {
  aspect-ratio: 0.781690141;
}
.py-\[16px\] {
  padding-top: 16px;
  padding-bottom: 16px;
}
.border-l-0 {
  border-left-width: 0px;
}
.border-r-0 {
  border-right-width: 0px;
}
.border-t-0 {
  border-top-width: 0px;
}
.border-b-0 {
  border-bottom-width: 0px;
}
.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
.border-solid {
  border-style: solid;
}
.w-\[auto\] {
  width: auto;
}

.h-\[32vh\] {
  height: 32vh;
}

.h-\[30vh\] {
  height: 30vh;
}

.inline-flex {
  display: inline-flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.max-w-screen-auto {
  max-width: auto;
}

.px-14 {
  padding-left: 3.5rem /* 56px */;
  padding-right: 3.5rem /* 56px */;
}

.w-fit {
  width: fit-content;
}

.w-1\/2 {
  width: 50%;
}
.w-\[60\.5\%\] {
  width: 60.5%;
}
.mb-\[5px\] {
  margin-bottom: 5px;
}

.p-16 {
  padding: 4rem /* 64px */;
}
.h-\[100px\] {
  height: 100px;
}
.h-\[60vh\] {
  height: 60vh;
}

.h-\[50vh\] {
  height: 50vh;
}

.h-\[40vh\] {
  height: 40vh;
}

.text-\[25px\] {
  font-size: 25px;
}

.h-7 {
  height: 1.5rem /* 28px */;
}
.w-7 {
  width: 1.5rem /* 28px */;
}

.h-\[80vh\] {
  height: 80vh;
}
.h-\[60px\] {
  height: 60px;
}

.h-\[150px\] {
  height: 150px;
}

.max-h-\[80vh\] {
  max-height: 80vh;
}
.-mt-\[8px\] {
  margin-top: -8px;
}

.mr-1 {
  margin-right: 0.25rem;
}
.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}
.bottom-\[45px\] {
  bottom: 45px;
}
.bottom-\[55px\] {
  bottom: 55px;
}
.bottom-\[70px\] {
  bottom: 70px;
}

.bottom-\[212px\] {
  bottom: 212px;
}

.bottom-\[230px\] {
  bottom: 230px;
}

.py-2\.5 {
  padding-top: 0.625rem /* 10px */;
  padding-bottom: 0.625rem /* 10px */;
}

.pl-3 {
  padding-left: 0.75rem /* 12px */;
}
.font-light {
  font-weight: 300;
}
.font-normal {
  font-weight: 400;
}
.font-semibold {
  font-weight: 600;
}

.w-\[227px\] {
  width: 227px;
}

.w-\[350px\] {
  width: 350px;
}

.w-\[457px\] {
  width: 457px;
}

.h-\[356px\] {
  height: 356px;
}

.max-w-none {
  max-width: none;
}
.left-0 {
  left: 0px;
}
.top-full {
  top: 100%;
}
.top-64 {
  top: 16rem /* 256px */;
}
.aspect-\[1052\/592\] {
  aspect-ratio: 1052 / 592;
}
.aspect-\[593\/592\] {
  aspect-ratio: 593 / 592;
}
.top-\[8\.5\%\] {
  top: 8.5%;
}
.top-\[10\%\] {
  top: 10%;
}
.w-\[157px\] {
  width: 157px;
}

.top-1\/2 {
  top: 50%;
}
.top-1\/3 {
  top: 33.3%;
}

.w-\[54px\] {
  width: 54px;
}

.h-\[54px\] {
  height: 54px;
}

.text-\[10px\] {
  font-size: 10px;
}

.ml-7 {
  margin-left: 1.75rem /* 28px */;
}

.mt-\[76px\] {
  margin-top: 76px;
}

.mt-\[20px\] {
  margin-top: 20px;
}

.w-\[90px\] {
  width: 90px;
}

.mt-\[12px\] {
  margin-top: 12px;
}

.mt-\[50px\] {
  margin-top: 50px;
}

.w-\[73\.5\%\] {
  width: 73.5%;
}

.border-t {
  border-top-width: 1px;
}

.pt-8 {
  padding-top: 2rem /* 32px */;
}
.pt-10 {
  padding-top: 2.5rem /* 40px */;
}
.text-\[22px\] {
  font-size: 22px;
}
.mt-2\.5 {
  margin-top: 0.625rem /* 10px */;
}
.px-9 {
  padding-left: 2.25rem /* 36px */;
  padding-right: 2.25rem /* 36px */;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.h-\[60vh\] {
  height: 60vh;
}

.text-5xl {
  font-size: 3rem /* 48px */;
  line-height: 1;
}

.tracking-wide {
  letter-spacing: 0.025em;
}

.duration-200 {
  transition-duration: 200ms;
}
.aspect-\[390\/167\] {
  aspect-ratio: 390 / 167;
}
.m-2 {
  margin: 0.5rem /* 8px */;
}
.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}
.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale)
    var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale)
    var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
}

.shadow-gray-500 {
  --tw-shadow-color: #6b7280;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-gray-700 {
  --tw-shadow-color: #374151;
  --tw-shadow: var(--tw-shadow-colored);
}

.w-\[617px\] {
  width: 617px;
}
.top-\[85\%\] {
  top: 85%;
}
.mb-6 {
  margin-bottom: 1.5rem /* 24px */;
}
.mb-7 {
  margin-bottom: 1.75rem /* 28px */;
}
.pl-\[8px\] {
  padding-left: 8px;
}
.w-\[65\%\] {
  width: 65%;
}
.h-\[62\%\] {
  height: 62%;
}
.w-\[32\%\] {
  width: 32%;
}
.w-\[35\%\] {
  width: 35%;
}
.h-\[38\%\] {
  height: 38%;
}
.gap-x-\[10vw\] {
  column-gap: 10vw;
}
.gap-y-\[3\.16vw\] {
  row-gap: 3.16vw;
}
.px-\[10px\] {
  padding-left: 10px;
  padding-right: 10px;
}
.pb-7 {
  padding-bottom: 1.75rem /* 28px */;
}

.m-1 {
  margin: 0.25rem /* 4px */;
}
.mt-\[12px\] {
  margin-top: 12px;
}
.top-\[45\%\] {
  top: 45%;
}
.w-32 {
  width: 8rem /* 128px */;
}
.last\:mb-0:last-child {
  margin-bottom: 0px;
}

.last\:mr-0:last-child {
  margin-right: 0px;
}

.w-\[27\%\] {
  width: 27%;
}

.w-\[70\%\] {
  width: 70%;
}

.pb-0 {
  padding-bottom: 0px;
}

.text-\[8px\] {
  font-size: 8px;
}

.pl-1 {
  padding-left: 0.25rem /* 4px */;
}

.indent-7 {
  text-indent: 1.75rem /* 28px */;
}
.pb-3 {
  padding-bottom: 0.75rem /* 12px */;
}

.pt-5 {
  padding-top: 1.25rem /* 20px */;
}
.pt-20 {
  padding-top: 5rem /* 80px */;
}
.max-h-\[400px\] {
  max-height: 400px;
}
.h-\[400px\] {
  height: 400px;
}
.h-\[421px\] {
  height: 421px;
}
.pb-20 {
  padding-bottom: 5rem /* 80px */;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.pt-0 {
  padding-top: 0px;
}

.pb-32 {
  padding-bottom: 8rem /* 128px */;
}

.w-\[604px\] {
  width: 604px;
}

.pr-0 {
  padding-right: 0px;
}

.translate-x-1\/2 {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
    scaleY(var(--tw-scale-y));
}

.right-1\/2 {
  right: 50%;
}

.pt-12 {
  padding-top: 3rem /* 48px */;
}

.pb-6 {
  padding-bottom: 1.5rem /* 24px */;
}

.h-\[85px\] {
  height: 85px;
}

.bottom-4 {
  bottom: 1rem /* 16px */;
}
.pb-6 {
  padding-bottom: 1.5rem /* 24px */;
}

.mx-6 {
  margin-left: 1.5rem /* 24px */;
  margin-right: 1.5rem /* 24px */;
}

.pt-4 {
  padding-top: 1rem /* 16px */;
}

.h-\[180px\] {
  height: 180px;
}
.pb-5 {
  padding-bottom: 1.25rem /* 20px */;
}

.h-\[175px\] {
  height: 175px;
}

.h-\[153px\] {
  height: 153px;
}

.text-\[18px\] {
  font-size: 18px;
}

.w-6 {
  width: 1.5rem /* 24px */;
}

.top-\[2px\] {
  top: 2px;
}
.mt-\[0\.5rem\] {
  margin-top: 0.5rem /* 8px */;
}

.my-\[0\.25rem\] {
  margin-top: 0.25rem /* 8px */;
  margin-bottom: 0.25rem /* 8px */;
}
.my-\[0\.5rem\] {
  margin-top: 0.5rem /* 8px */;
  margin-bottom: 0.5rem /* 8px */;
}
.my-\[0\.75rem\] {
  margin-top: 0.75rem /* 8px */;
  margin-bottom: 0.75rem /* 8px */;
}

.pt-6 {
  padding-top: 1.5rem /* 24px */;
}

.mt-\[0\.5rem\] {
  margin-top: 0.5rem /* 4px */;
}

.pb-16 {
  padding-bottom: 4rem /* 64px */;
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.mb-\[1rem\] {
  margin-bottom: 1rem /* 16px */;
}

.pb-10 {
  padding-bottom: 2.5rem /* 40px */;
}

.py-\[1rem\] {
  padding-top: 1rem /* 16px */;
  padding-bottom: 1rem /* 16px */;
}
.text-left {
  text-align: left;
}
.text-lg {
  font-size: 1.125rem /* 18px */;
  line-height: 1.75rem /* 28px */;
}
.text-center {
  text-align: center;
}
.tw-text-center {
  text-align: center;
}

.tw-line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.mb-10 {
  margin-bottom: 2.5rem /* 80px */;
}
.pt-\[15px\] {
  padding-top: 15px;
}

.h-\[97px\] {
  height: 97px;
}
.mb-\[0px\] {
  margin-bottom: 0px;
}

.gap-y-1 {
  row-gap: 0.25rem /* 4px */;
}
.gap-4 {
  gap: 1rem /* 16px */;
}
.top-3 {
  top: 0.75rem /* 12px */;
}

.w-\[42\%\] {
  width: 42%;
}

.font-light {
  font-weight: 300;
}
.h-\[98px\] {
  height: 98px;
}
.h-9 {
  height: 2.25rem /* 36px */;
}
.px-\[11px\] {
  padding-left: 11px;
  padding-right: 11px;
}

.py-\[2px\] {
  padding-top: 2px;
  padding-bottom: 2px;
}

.p-\[8px\] {
  padding: 8px;
}

.text-\[13px\] {
  font-size: 13px;
}

.mr-4 {
  margin-right: 1rem /* 16px */;
}
.py-\[4px\] {
  padding-top: 4px;
  padding-bottom: 4px;
}
.mr-\[8px\] {
  margin-right: 8px;
}

.px-\[8px\] {
  padding-left: 8px;
  padding-right: 8px;
}

.-ml-3 {
  margin-left: -0.75rem /* -12px */;
}

.h-\[185px\] {
  height: 185px;
}

.w-\[135px\] {
  width: 135px;
}

.h-\[24\%\] {
  height: 24%;
}

.py-10 {
  padding-top: 2.5rem /* 40px */;
  padding-bottom: 2.5rem /* 40px */;
}

.h-\[209px\] {
  height: 209px;
}

.w-\[282px\] {
  width: 282px;
}
.w-\[104px\] {
  width: 104px;
}

.py-\[0px\] {
  padding-top: 0px;
  padding-bottom: 0px;
}

.h-\[240px\] {
  height: 240px;
}
.h-\[182px\] {
  height: 182px;
}
.w-\[64\%\] {
  width: 64%;
}
.mt-\[12px\] {
  margin-top: 12px;
}
.p-\[12px\] {
  padding: 12px;
}
.mr-\[0px\] {
  margin-right: 0px;
}
.pt-\[12px\] {
  padding-top: 12px;
}

.mb-\[8px\] {
  margin-bottom: 8px;
}

.py-8 {
  padding-top: 2rem /* 32px */;
  padding-bottom: 2rem /* 32px */;
}
.h-\[127px\] {
  height: 127px;
}
.h-\[73px\] {
  height: 73px;
}
.px-\[8px\] {
  padding-left: 8px;
  padding-right: 8px;
}
.w-\[41\%\] {
  width: 41%;
}
.rounded-3xl {
  border-radius: 1.5rem /* 24px */;
}
.ml-\[0px\] {
  margin-left: 0px;
}

.ml-\[8px\] {
  margin-left: 8px;
}
.py-\[8px\] {
  padding-top: 8px;
  padding-bottom: 8px;
}

.pb-8 {
  padding-bottom: 2rem /* 32px */;
}
.h-\[393px\] {
  height: 393px;
}

.py-\[0px\] {
  padding-top: 0px;
  padding-bottom: 0px;
}
.h-\[211px\] {
  height: 211px;
}
.h-\[128px\] {
  height: 128px;
}
.p-\[16px\] {
  padding: 16px;
}
.pt-\[16px\] {
  padding-top: 16px;
}

.h-9 {
  height: 2.25rem /* 36px */;
}
.px-\[18px\] {
  padding-left: 18px;
  padding-right: 18px;
}
.pr-\[8px\] {
  padding-right: 8px;
}
.max-h-\[400px\] {
  max-height: 400px;
}
.pl-\[7px\] {
  padding-left: 7px;
}
.mt-\[16px\] {
  margin-top: 16px;
}
.gap-y-2 {
  row-gap: 0.5rem /* 8px */;
}
.mb-\[\.25ch\] {
  margin-bottom: 0.25ch;
}
.mb-\[\.125pc\] {
  margin-bottom: 0.125pc;
}

.h-\[12\%\] {
  height: 12%;
}

.h-\[107px\] {
  height: 107px;
}
.h-\[95\%\] {
  height: 95%;
}
.px-\[4px\] {
  padding-left: 4px;
  padding-right: 4px;
}

.mx-\[16px\] {
  margin-left: 16px;
  margin-right: 16px;
}

.w-\[82px\] {
  width: 82px;
}

.h-\[470px\] {
  height: 470px;
}

.h-\[47px\] {
  height: 47px;
}
.ml-\[4px\] {
  margin-left: 4px;
}

.min-h-\[37vh\] {
  min-height: 37vh;
}
.mt-\[8px\] {
  margin-top: 8px;
}

.-mt-\[8px\] {
  margin-top: -8px;
}
.hover\:w-\[200px\]:hover {
  width: 200px;
}

.h-\[100px\] {
  height: 100px;
}
.placeholder\:italic::placeholder {
  font-style: italic;
}
.placeholder\:text-gray-300::placeholder {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}
.border {
  border-width: 1px;
}
.rounded-\[inherit\] {
  border-radius: inherit;
}
.px-\[1px\] {
  padding-left: 1px;
  padding-right: 1px;
}
.font-thin {
  font-weight: 100;
}

.w-9\/12 {
  width: 75%;
}
.mt-auto {
  margin-top: auto;
}
.h-\[30vh\] {
  height: 30vh;
}
.bg-center {
  background-position: center;
}

.w-\[720px\] {
  width: 720px;
}
.w-\[270\%\] {
  width: 270%;
}
.h-\[45vw\] {
  height: 45vw;
}
.text-\[\#fefefe\] {
  --tw-text-opacity: 1;
  color: rgb(254 254 254 / var(--tw-text-opacity));
}

.mt-\[4px\] {
  margin-top: 4px;
}

.leading-5 {
  line-height: 1.25rem /* 20px */;
}

.h-\[3px\] {
  height: 3px;
}

.w-\[3px\] {
  width: 3px;
}

.px-9 {
  padding-left: 2.25rem /* 36px */;
  padding-right: 2.25rem /* 36px */;
}
.py-\[4px\] {
  padding-top: 4px;
  padding-bottom: 4px;
}
.tracking-normal {
  letter-spacing: 0em;
}
.h-\[108px\] {
  height: 108px;
}
.left-\[3vw\] {
  left: 3vw;
}
.justify-center {
  justify-content: center;
}
.justify-start {
  justify-content: flex-start;
}
.flex-col-reverse {
  flex-direction: column-reverse;
}
.top-\[45\%\] {
  top: 45%;
}

.right-\[2\.25vw\] {
  right: 2.25vw;
}

.w-\[139px\] {
  width: 139px;
}
.w-\[43px\] {
  width: 43px;
}
.h-\[29px\] {
  height: 29px;
}
.h-\[132px\] {
  height: 132px;
}
.h-\[172px\] {
  height: 172px;
}
.px-\[4px\] {
  padding-left: 4px;
  padding-right: 4px;
}
.w-\[94vw\] {
  width: 94vw;
}

.h-\[53\.7vw\] {
  height: 53.7vw;
}

.p-\[20px\] {
  padding: 20px;
}

.bg-\[\#f64c72\] {
  --tw-bg-opacity: 1;
  background-color: rgb(246 76 114 / var(--tw-bg-opacity));
}
.h-6 {
  height: 1.5rem /* 24px */;
}
.py-\[6px\] {
  padding-top: 6px;
  padding-bottom: 6px;
}
.h-\[122px\] {
  height: 122px;
}
.px-\[4px\] {
  padding-left: 4px;
  padding-right: 4px;
}
.mx-\[10px\] {
  margin-left: 10px;
  margin-right: 10px;
}
.w-\[76\.6vw\] {
  width: 76.6vw;
}

.text-\[12px\] {
  font-size: 12px;
}
.mt-\[11px\] {
  margin-top: 11px;
}
.ml-\[4px\] {
  margin-left: 4px;
}

.h-\[20vw\] {
  height: 20vw;
}

.w-\[46\.4\%\] {
  width: 46.4%;
}
.border-0 {
  border-width: 0px;
}
.rounded-lg {
  border-radius: 0.5rem /* 8px */;
}
.mt-6 {
  margin-top: 1.5rem /* 24px */;
}
.w-\[15vw\] {
  width: 15vw;
}
.-right-16 {
  right: -4rem /* -64px */;
}
.pt-\[4\.62vw\] {
  padding-top: 4.62vw;
}
.pl-\[4vw\] {
  padding-left: 4vw;
}
.h-\[175px\] {
  height: 175px;
}
.right-\[50\%\] {
  right: 50%;
}
.bottom-\[2\%\] {
  bottom: 2%;
}
.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
    scaleY(var(--tw-scale-y));
}
.translate-x-1\/2 {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
    scaleY(var(--tw-scale-y));
}
.inline {
  display: inline;
}

.max-w-\[270\%\] {
  max-width: 270%;
}
.h-2\/3 {
  height: 66.666667%;
}
.h-5\/6 {
  height: 83.333333%;
}
.h-24 {
  height: 6rem /* 96px */;
}

.py-7 {
  padding-top: 1.75rem /* 28px */;
  padding-bottom: 1.75rem /* 28px */;
}

.space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
}

.w-\[50px\] {
  width: 50px;
}

.h-\[22px\] {
  height: 22px;
}
.h-11 {
  height: 2.75rem /* 44px */;
}
.gap-x-2 {
  column-gap: 0.5rem /* 8px */;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.m-auto {
  margin: auto;
}
.w-\[88\%\] {
  width: 88%;
}
.hover\:w-\[300px\]:hover {
  width: 300px;
}
.group:hover .group-hover\:block {
  display: block;
}
.bg-\[\#212b37\] {
  --tw-bg-opacity: 1;
  background-color: rgb(33 43 55 / var(--tw-bg-opacity));
}
.text-\[\#5fc1f2\] {
  --tw-text-opacity: 1;
  color: rgb(95 193 242 / var(--tw-text-opacity));
}
.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
    scaleY(var(--tw-scale-y));
}
.bg-\[rgba\(0\2c 0\2c 0\2c 0\.8\)\] {
  background-color: rgba(0, 0, 0, 0.8);
}
.pb-\[16px\] {
  padding-bottom: 16px;
}
.mb-8 {
  margin-bottom: 2rem /* 32px */;
}
.py-7 {
  padding-top: 1.75rem /* 28px */;
  padding-bottom: 1.75rem /* 28px */;
}
.pb-9 {
  padding-bottom: 2.25rem /* 36px */;
}
.-bottom-8 {
  bottom: -2rem /* -32px */;
}
.gap-x-16 {
  column-gap: 4rem /* 64px */;
}

.h-\[34px\] {
  height: 34px;
}
.h-\[18\.7vw\] {
  height: 18.7vw;
}
.w-\[78px\] {
  width: 78px;
}
.first\:ml-0:first-child {
  margin-left: 0px;
}
.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}
.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}
.mr-auto {
  margin-right: auto;
}
.w-5\/12 {
  width: 41.666667%;
}
.w-7\/12 {
  width: 58.333333%;
}
.right-4 {
  right: 1rem /* 16px */;
}
.gap-y-3 {
  row-gap: 0.75rem /* 12px */;
}
.w-\[560px\] {
  width: 560px;
}
.gap-x-\[43px\] {
  column-gap: 43px;
}
.pt-14 {
  padding-top: 3.5rem /* 56px */;
}
.h-\[30rem\] {
  height: 30rem /* 320px */;
}

.text-\[\.85vw\] {
  font-size: 0.85vw;
}
.from-\[\#d20c83\] {
  --tw-gradient-from: #d20c83;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(210 12 131 / 0));
}
.to-\[\#0dedf5\] {
  --tw-gradient-to: #0dedf5;
}
.mt-\[0px\] {
  margin-top: 0px;
}

.h-28 {
  height: 7rem /* 112px */;
}
.text-\[11px\] {
  font-size: 11px;
}
.text-\[9px\] {
  font-size: 9px;
}
.text-\[8px\] {
  font-size: 8px;
}
.p-\[6px\] {
  padding: 6px;
}
.right-1\/2 {
  right: 50%;
}
.translate-x-1\/2 {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
    scaleY(var(--tw-scale-y));
}
.translate-y-1\/4 {
  --tw-translate-y: 25%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
    scaleY(var(--tw-scale-y));
}
.w-2\/5 {
  width: 40%;
}
.w-3\/5 {
  width: 60%;
}
.pt-1 {
  padding-top: 0.25rem /* 4px */;
}
.bg-\[\#1b242f\] {
  --tw-bg-opacity: 1;
  background-color: rgb(27 36 47 / var(--tw-bg-opacity));
}
.h-\[7vh\] {
  height: 7vh;
}
.mt-\[-7vh\] {
  margin-top: -7vh;
}
.h-\[8\.5rem\] {
  height: 8.5rem /* 136px */;
}

.w-14 {
  width: 3.5rem /* 56px */;
}

.justify-around {
  justify-content: space-around;
}

.h-\[89\%\] {
  height: 89%;
}
.aspect-\[370\/404\] {
  aspect-ratio: 370 / 404;
}
@media (min-width: 640px) {
  .sm\:text-\[13px\] {
    font-size: 13px;
  }
  .sm\:text-\[10px\] {
    font-size: 10px;
  }
  .sm\:p-2 {
    padding: 0.5rem /* 8px */;
  }
  .sm\:w-full {
    width: 100%;
  }
  .sm\:pl-0 {
    padding-left: 0px;
  }
  .sm\:inline {
    display: inline;
  }
  .sm\:-bottom-\[45px\] {
    bottom: -45px;
  }
  .sm\:block {
    display: block;
  }
  .sm\:h-\[114px\] {
    height: 114px;
  }
  .sm\:transform-none {
    transform: none;
  }
  .sm\:bottom-0 {
    bottom: 0px;
  }
  .sm\:right-\[7\%\] {
    right: 7%;
  }
  .sm\:mb-\[2vw\] {
    margin-bottom: 2vw;
  }
  .sm\:border {
    border-width: 1px;
  }
  .sm\:h-\[53px\] {
    height: 53px;
  }
  .sm\:w-\[490px\] {
    width: 490px;
  }
  .sm\:ml-\[2px\] {
    margin-left: 2px;
  }
  .sm\:h-\[53px\] {
    height: 53px;
  }
  .sm\:h-\[10vh\] {
    height: 10vh;
  }
  .sm\:mb-\[16px\] {
    margin-bottom: 16px;
  }
  .sm\:w-auto {
    width: auto;
  }
  .sm\:w-\[70\%\] {
    width: 70%;
  }
  .sm\:w-\[490px\] {
    width: 490px;
  }
  .sm\:flex-row {
    flex-direction: row;
  }
  .sm\:h-\[90\%\] {
    height: 90%;
  }
  .sm\:px-\[2\.75ch\] {
    padding-left: 2.75ch;
    padding-right: 2.75ch;
  }
  .sm\:mt-\[8px\] {
    margin-top: 8px;
  }
  .sm\:mt-10 {
    margin-top: 2.5rem /* 40px */;
  }
  .sm\:mt-14 {
    margin-top: 3.5rem /* 56px */;
  }
  .sm\:text-xl {
    font-size: 1.25rem /* 20px */;
    line-height: 1.75rem /* 28px */;
  }
  .sm\:mb-\[1ch\] {
    margin-bottom: 1ch;
  }
  .sm\:pt-\[8px\] {
    padding-top: 8px;
  }
  .sm\:pt-10 {
    padding-top: 2.5rem /* 40px */;
  }
  .sm\:h-auto {
    height: auto;
  }
  .sm\:mb-2\.5 {
    margin-bottom: 0.625rem /* 10px */;
  }
  .sm\:h-\[40\%\] {
    height: 40%;
  }
  .sm\:h-\[48\%\] {
    height: 48%;
  }
  .sm\:h-\[80\.7vw\] {
    height: 80.7vw;
  }
  .sm\:px-\[5\%\] {
    padding-left: 5%;
    padding-right: 5%;
  }
  .sm\:w-\[31\%\] {
    width: 31%;
  }
  .sm\:h-\[16\.58vw\] {
    height: 16.58vw;
  }
  .sm\:mb-\[2vw\] {
    margin-bottom: 2vw;
  }
  .sm\:ml-\[2\.3\%\] {
    margin-left: 2.3%;
  }
  .sm\:h-auto {
    height: auto;
  }
  .sm\:h-\[23\.1vw\] {
    height: 23.1vw;
  }
  .sm\:h-\[32\.5vw\] {
    height: 32.5vw;
  }
  .sm\:py-3 {
    padding-top: 0.75rem /* 12px */;
    padding-bottom: 0.75rem /* 12px */;
  }
  .sm\:ml-\[2px\] {
    margin-left: 2px;
  }
  .sm\:h-\[244px\] {
    height: 244px;
  }
  .sm\:h-\[18\%\] {
    height: 18%;
  }
  .sm\:w-\[300px\] {
    width: 300px;
  }
  .sm\:mr-12 {
    margin-right: 3rem /* 48px */;
  }
  .sm\:ml-0 {
    margin-left: 0px;
  }
  .sm\:w-\[700px\] {
    width: 700px;
  }
  .sm\:h-\[15vh\] {
    height: 15vh;
  }
  .sm\:border-none {
    border-style: none;
  }
  .sm\:pt-\[40px\] {
    padding-top: 40px;
  }
  .sm\:mt-8 {
    margin-top: 2rem /* 32px */;
  }
  .sm\:py-6 {
    padding-top: 1.5rem /* 24px */;
    padding-bottom: 1.5rem /* 24px */;
  }
  .sm\:mt-0 {
    margin-top: 0px;
  }
  .sm\:pb-0 {
    padding-bottom: 0px;
  }
  .sm\:py-4 {
    padding-top: 1rem /* 16px */;
    padding-bottom: 1rem /* 16px */;
  }
  .sm\:px-16 {
    padding-left: 4rem /* 64px */;
    padding-right: 4rem /* 64px */;
  }
  .sm\:text-sm {
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
  }
  .sm\:mb-5 {
    margin-bottom: 1.25rem /* 20px */;
  }
  .sm\:text-base {
    font-size: 1rem /* 16px */;
    line-height: 1.5rem /* 24px */;
  }
  .sm\:text-2xl {
    font-size: 1.5rem /* 24px */;
    line-height: 2rem /* 32px */;
  }
  .sm\:h-12 {
    height: 3rem /* 48px */;
  }
  .sm\:h-\[42vh\] {
    height: 42vh;
  }

  .sm\:h-\[40vh\] {
    height: 40vh;
  }

  .sm\:text-4xl {
    font-size: 2.25rem /* 36px */;
    line-height: 2.5rem /* 40px */;
  }
  .sm\:w-\[305px\] {
    width: 305px;
  }

  .sm\:w-\[468px\] {
    width: 468px;
  }

  .sm\:w-\[613px\] {
    width: 613px;
  }

  .sm\:h-\[477px\] {
    height: 477px;
  }

  .sm\:mb-5 {
    margin-bottom: 1.25rem /* 20px */;
  }

  .sm\:p-16 {
    padding: 4rem /* 64px */;
  }

  .sm\:justify-start {
    justify-content: flex-start;
  }
  .sm\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .sm\:flex-row {
    flex-direction: row;
  }
  .sm\:py-\[\.3ch\] {
    padding-top: 0.3ch;
    padding-bottom: 0.3ch;
  }
  .sm\:px-\[2\.75ch\] {
    padding-left: 2.75ch;
    padding-right: 2.75ch;
  }
  .sm\:py-\[\.3ch\] {
    padding-top: 0.3ch;
    padding-bottom: 0.3ch;
  }
  .sm\:h-28 {
    height: 7rem /* 112px */;
  }
  .sm\:text-3xl {
    font-size: 1.875rem /* 30px */;
    line-height: 2.25rem /* 36px */;
  }
  .sm\:px-10 {
    padding-left: 2.5rem /* 40px */;
    padding-right: 2.5rem /* 40px */;
  }
  .sm\:ml-10 {
    margin-left: 2.5rem /* 40px */;
  }
  .sm\:h-\[412px\] {
    height: 412px;
  }
  .sm\:h-\[518px\] {
    height: 518px;
  }
  .sm\:w-\[31\.5\%\] {
    width: 31.5%;
  }
  .sm\:text-\[14px\] {
    font-size: 14px;
  }
  .sm\:font-normal {
    font-weight: 400;
  }
  .sm\:text-sm {
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
  }
  .sm\:mb-4 {
    margin-bottom: 1rem /* 16px */;
  }
  .sm\:text-xs {
    font-size: 0.75rem /* 12px */;
    line-height: 1rem /* 16px */;
  }
  .sm\:leading-5 {
    line-height: 1.25rem /* 20px */;
  }
  .sm\:w-\[67\.8vw\] {
    width: 67.8vw;
  }
  .sm\:mb-\[\.5ch\] {
    margin-bottom: 0.5ch;
  }
  .sm\:justify-between {
    justify-content: space-between;
  }
  .sm\:px-\[10px\] {
    padding-left: 10px;
    padding-right: 10px;
  }
  .sm\:px-\[26px\] {
    padding-left: 26px;
    padding-right: 26px;
  }
  .sm\:py-\[30px\] {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .sm\:w-\[422px\] {
    width: 422px;
  }

  .sm\:w-\[414px\] {
    width: 414px;
  }
  .sm\:mt-6 {
    margin-top: 1.5rem /* 24px */;
  }
  .sm\:h-\[45vh\] {
    height: 45vh;
  }
  .sm\:p-6 {
    padding: 1.5rem /* 24px */;
  }
  .sm\:w-\[170px\] {
    width: 170px;
  }
  .sm\:mr-\[2\.25vw\] {
    margin-right: 2.25vw;
  }
  .sm\:h-\[41vw\] {
    height: 41vw;
  }
  .sm\:h-\[154px\] {
    height: 154px;
  }
  .sm\:mr-\[5vw\] {
    margin-right: 5vw;
  }
  .sm\:w-\[139px\] {
    width: 139px;
  }
  .sm\:px-\[10px\] {
    padding-left: 10px;
    padding-right: 10px;
  }
  .sm\:h-\[220px\] {
    height: 220px;
  }
  .sm\:min-h-\[85px\] {
    min-height: 85px;
  }
  .sm\:w-\[80vw\] {
    width: 80vw;
  }
  .sm\:h-\[45vw\] {
    height: 45vw;
  }

  .sm\:h-\[117px\] {
    height: 117px;
  }
  .sm\:h-\[129px\] {
    height: 129px;
  }
  .sm\:mx-10 {
    margin-left: 2.5rem /* 40px */;
    margin-right: 2.5rem /* 40px */;
  }
  .sm\:py-\[6px\] {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .sm\:w-\[70vw\] {
    width: 70vw;
  }
  .sm\:h-\[18\.3vw\] {
    height: 18.3vw;
  }
  .sm\:gap-x-\[5\%\] {
    column-gap: 5%;
  }
  .sm\:w-\[47\%\] {
    width: 47%;
  }
}

@media (min-width: 768px) {
  .md\:h-60 {
    height: 15rem /* 240px */;
  }
  .md\:ml-2 {
    margin-left: 0.5rem /* 8px */;
  }
  .md\:w-fit {
    width: fit-content;
  }
  .md\:p-6 {
    padding: 1.5rem /* 24px */;
  }
  .md\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .md\:w-\[400px\] {
    width: 400px;
  }
  .md\:w-\[17\.25\%\] {
    width: 17.25%;
  }
  .md\:gap-x-\[3\%\] {
    column-gap: 3%;
  }
  .md\:border-b {
    border-bottom-width: 1px;
  }
  .md\:flex-wrap {
    flex-wrap: wrap;
  }
  .md\:gap-x-\[min\(5vw\2c 71px\)\] {
    column-gap: min(5vw, 71px);
  }
  .md\:h-\[20rem\] {
    height: 20rem /* 320px */;
  }
  .md\:text-3xl {
    font-size: 1.875rem /* 30px */;
    line-height: 2.25rem /* 36px */;
  }
  .md\:w-6 {
    width: 1.5rem /* 24px */;
  }
  .md\:w-\[82\%\] {
    width: 82%;
  }
  .md\:w-\[150px\] {
    width: 150px;
  }
  .md\:mx-2 {
    margin-left: 0.5rem /* 8px */;
    margin-right: 0.5rem /* 8px */;
  }
  .md\:h-\[153px\] {
    height: 153px;
  }
  .md\:w-9\/12 {
    width: 75%;
  }
  .md\:items-stretch {
    align-items: stretch;
  }
  .md\:pt-\[45px\] {
    padding-top: 45px;
  }
  .md\:h-\[48\.4vw\] {
    height: 48.4vw;
  }
  .md\:mb-\[8px\] {
    margin-bottom: 8px;
  }
  .md\:py-1 {
    padding-top: 0.25rem /* 4px */;
    padding-bottom: 0.25rem /* 4px */;
  }
  .md\:text-sm {
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
  }
  .md\:text-center {
    text-align: center;
  }
  .md\:py-7 {
    padding-top: 1.75rem /* 28px */;
    padding-bottom: 1.75rem /* 28px */;
  }
  .md\:mx-10 {
    margin-left: 2.5rem /* 40px */;
    margin-right: 2.5rem /* 40px */;
  }
  .md\:mr-0 {
    margin-right: 0px;
  }
  .md\:text-lg {
    font-size: 1.125rem /* 18px */;
    line-height: 1.75rem /* 28px */;
  }
  .md\:self-start {
    align-self: flex-start;
  }
  .md\:items-baseline {
    align-items: baseline;
  }
  .md\:block {
    display: block;
  }
  .md\:mt-8 {
    margin-top: 2rem /* 32px */;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }
  .md\:text-left {
    text-align: left;
  }
  .md\:w-10 {
    width: 2.5rem /* 40px */;
  }
  .md\:w-20 {
    width: 5rem /* 80px */;
  }
  .md\:m-6 {
    margin: 1.5rem /* 24px */;
  }
  .md\:px-4 {
    padding-left: 1rem /* 16px */;
    padding-right: 1rem /* 16px */;
  }
  .md\:h-10 {
    height: 2.5rem /* 40px */;
  }
  .md\:h-full {
    height: 100%;
  }
  .md\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }
  .md\:px-5 {
    padding-left: 1.25rem /* 20px */;
    padding-right: 1.25rem /* 20px */;
  }
  .md\:w-1\/2 {
    width: 50%;
  }
  .md\:object-cover {
    object-fit: cover;
  }
  .md\:w-\[382px\] {
    width: 382px;
  }

  .md\:w-\[589px\] {
    width: 589px;
  }

  .md\:p-7 {
    padding: 1.75rem /* 28px */;
  }

  .md\:w-\[597px\] {
    width: 597px;
  }
  .md\:text-\[26px\] {
    font-size: 26px;
  }

  .md\:w-\[764px\] {
    width: 764px;
  }

  .md\:w-\[90\%\] {
    width: 90%;
  }
  .md\:h-12 {
    height: 3rem /* 48px */;
  }
  .md\:w-full {
    width: 100%;
  }
  .md\:block {
    display: block;
  }
  .md\:w-1\/4 {
    width: 25%;
  }
  .md\:h-\[68px\] {
    height: 68px;
  }
  .md\:py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .md\:top-\[6px\] {
    top: 6px;
  }
  .md\:h-\[60vh\] {
    height: 60vh;
  }
  .md\:items-end {
    align-items: flex-end;
  }
  .md\:right-1 {
    right: 0.25rem /* 4px */;
  }
  .md\:bottom-2 {
    bottom: 0.5rem /* 8px */;
  }
  .md\:w-48 {
    width: 12rem /* 192px */;
  }
  .md\:mr-6 {
    margin-right: 1.5rem /* 24px */;
  }
  .md\:h-\[5px\] {
    height: 5px;
  }
  .md\:w-\[5px\] {
    width: 5px;
  }
  .md\:pt-6 {
    padding-top: 1.5rem /* 24px */;
  }
  .md\:h-16 {
    height: 4rem /* 64px */;
  }
  .md\:pb-0 {
    padding-bottom: 0px;
  }
  .md\:mr-0 {
    margin-right: 0px;
  }
  .md\:max-w-md {
    max-width: 28rem /* 448px */;
  }
  .md\:text-xs {
    font-size: 0.75rem /* 12px */;
    line-height: 1rem /* 16px */;
  }
  .md\:p-2 {
    padding: 0.5rem /* 8px */;
  }

  .md\:mt-1 {
    margin-top: 0.25rem /* 4px */;
  }
  .md\:py-1 {
    padding-top: 0.25rem /* 4px */;
    padding-bottom: 0.25rem /* 4px */;
  }

  .md\:px-3 {
    padding-left: 0.75rem /* 12px */;
    padding-right: 0.75rem /* 12px */;
  }
  .md\:pr-8 {
    padding-right: 2rem /* 32px */;
  }
  .md\:right-0 {
    right: 0px;
  }

  .md\:top-0 {
    top: 0px;
  }
  .md\:w-\[85px\] {
    width: 85px;
  }
  .md\:h-\[48\.72px\] {
    height: 48.72px;
  }

  .md\:py-2\.5 {
    padding-top: 0.625rem /* 10px */;
    padding-bottom: 0.625rem /* 10px */;
  }
  .md\:px-10 {
    padding-left: 2.5rem /* 40px */;
    padding-right: 2.5rem /* 40px */;
  }
  .md\:px-12 {
    padding-left: 3rem /* 48px */;
    padding-right: 3rem /* 48px */;
  }
  .md\:mr-16 {
    margin-right: 4rem /* 64px */;
  }
  .md\:translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y));
  }
  .md\:grid {
    display: grid;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:m-4 {
    margin: 1rem;
  }

  .md\:w-\[80\%\] {
    width: 80%;
  }

  .md\:h-20 {
    height: 5rem;
  }

  .md\:mt-6 {
    margin-top: 1.5rem;
  }

  .md\:h-\[60vh\] {
    height: 60vh;
  }
  .md\:h-\[50vh\] {
    height: 50vh;
  }

  .md\:w-5\/6 {
    width: 83.333333%;
  }

  .md\:pl-10 {
    padding-left: 2.5rem;
  }

  .md\:bottom-\[-160px\] {
    bottom: -160px;
  }

  .md\:bottom-\[295px\] {
    bottom: 295px;
  }

  .md\:bottom-\[270px\] {
    bottom: 270px;
  }

  .md\:bottom-\[290px\] {
    bottom: 290px;
  }

  .md\:w-\[75\%\] {
    width: 75%;
  }

  .md\:h-\[200px\] {
    height: 200px;
  }
  .md\:h-\[250px\] {
    height: 250px;
  }

  .md\:bottom-\[70px\] {
    bottom: 70px;
  }

  .md\:bottom-\[90px\] {
    bottom: 90px;
  }

  .md\:my-4 {
    margin-top: 1rem /* 16px */;
    margin-bottom: 1rem /* 16px */;
  }
  .md\:h-\[62vh\] {
    height: 62vh;
  }

  .md\:h-\[60vh\] {
    height: 60vh;
  }
  .md\:text-\[20px\] {
    font-size: 20px;
  }

  .md\:text-\[50px\] {
    font-size: 50px;
  }

  .md\:py-5 {
    padding-top: 1.25rem /* 20px */;
    padding-bottom: 1.25rem /* 20px */;
  }
  .md\:flex-row {
    flex-direction: row;
  }

  .md\:w-\[83\%\] {
    width: 83%;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-4 {
    width: 1rem /* 16px */;
  }
  .md\:h-4 {
    height: 1rem /* 16px */;
  }
  .md\:mr-10 {
    margin-right: 2.5rem /* 40px */;
  }
  .md\:py-\[\.3ch\] {
    padding-top: 0.3ch;
    padding-bottom: 0.3ch;
  }
  .md\:text-xl {
    font-size: 1.25rem /* 20px */;
    line-height: 1.75rem /* 28px */;
  }
  .md\:h-\[150px\] {
    height: 150px;
  }
  .md\:w-\[57\%\] {
    width: 57%;
  }
  .md\:w-8 {
    width: 2rem /* 32px */;
  }
  .md\:h-8 {
    height: 2rem /* 32px */;
  }
  .md\:p-3 {
    padding: 0.75rem /* 12px */;
  }
  .md\:h-\[325px\] {
    height: 325px;
  }
  .md\:ml-20 {
    margin-left: 5rem /* 80px */;
  }
  .md\:text-base {
    font-size: 1rem /* 16px */;
    line-height: 1.5rem /* 24px */;
  }
  .md\:flex {
    display: flex;
  }
  .md\:py-5 {
    padding-top: 1.25rem /* 20px */;
    padding-bottom: 1.25rem /* 20px */;
  }
  .md\:w-\[40\.65vw\] {
    width: 40.65vw;
  }
  .md\:py-\[\.3ch\] {
    padding-top: 0.3ch;
    padding-bottom: 0.3ch;
  }
  .md\:w-\[85px\] {
    width: 85px;
  }
  .md\:h-\[48\.72px\] {
    height: 48.72px;
  }

  .md\:w-1\/4 {
    width: 25%;
  }

  .md\:p-0 {
    padding: 0px;
  }
  .md\:h-\[60vh\] {
    height: 60vh;
  }
  .md\:mt-5 {
    margin-top: 1.25rem /* 20px */;
  }
  .md\:py-8 {
    padding-top: 2rem /* 32px */;
    padding-bottom: 2rem /* 32px */;
  }
  .md\:px-14 {
    padding-left: 3.5rem /* 56px */;
    padding-right: 3.5rem /* 56px */;
  }
  .md\:w-\[200px\] {
    width: 200px;
  }
  .md\:h-\[190px\] {
    height: 190px;
  }

  .md\:ml-10 {
    margin-left: 2.5rem /* 40px */;
  }
  .md\:w-9 {
    width: 2.25rem /* 36px */;
  }
  .md\:w-\[20vw\] {
    width: 20vw;
  }
  .md\:px-\[16px\] {
    padding-left: 16px;
    padding-right: 16px;
  }
  .md\:h-\[250px\] {
    height: 250px;
  }
  .md\:h-\[30\.2vw\] {
    height: 30.2vw;
  }
  .md\:py-\[8px\] {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .md\:w-\[657px\] {
    width: 657px;
  }

  .md\:h-\[375px\] {
    height: 375px;
  }

  .md\:h-\[137px\] {
    height: 137px;
  }

  .md\:mx-16 {
    margin-left: 4rem /* 64px */;
    margin-right: 4rem /* 64px */;
  }

  .md\:w-\[60vw\] {
    width: 60vw;
  }
  .md\:py-4 {
    padding-top: 1rem /* 16px */;
    padding-bottom: 1rem /* 16px */;
  }
  .md\:h-\[150px\] {
    height: 150px;
  }
  .md\:h-\[15\.7vw\] {
    height: 15.7vw;
  }

  .md\:max-w-full {
    max-width: 100%;
  }
  .md\:mt-32 {
    margin-top: 8rem /* 128px */;
  }
  .md\:h-14 {
    height: 3.5rem /* 56px */;
  }
  .md\:h-7 {
    height: 1.75rem /* 28px */;
  }
  .md\:ml-10 {
    margin-left: 2.5rem /* 40px */;
  }
  .md\:w-7 {
    width: 1.75rem /* 28px */;
  }
  .md\:text-\[10px\] {
    font-size: 10px;
  }
  .md\:py-10 {
    padding-top: 2.5rem /* 40px */;
    padding-bottom: 2.5rem /* 40px */;
  }
  .md\:text-\[15px\] {
    font-size: 15px;
  }
  .md\:h-\[12rem\] {
    height: 12rem /* 192px */;
  }
}

@media (min-width: 1024px) {
  .lg\:w-\[34px\] {
    width: 34px;
  }
  .lg\:h-\[34px\] {
    height: 34px;
  }
  .lg\:w-\[63\%\] {
    width: 63%;
  }
  .lg\:pb-20 {
    padding-bottom: 5rem /* 80px */;
  }
  .lg\:py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .lg\:w-12 {
    width: 3rem /* 48px */;
  }
  .lg\:h-\[14rem\] {
    height: 14rem /* 224px */;
  }
  .lg\:h-\[15rem\] {
    height: 15rem /* 224px */;
  }
  .lg\:mt-\[-10vh\] {
    margin-top: -10vh;
  }

  .lg\:mt-\[-8vh\] {
    margin-top: -8vh;
  }
  .lg\:gap-y-1\.5 {
    row-gap: 0.375rem /* 6px */;
  }
  .lg\:px-3 {
    padding-left: 0.75rem /* 12px */;
    padding-right: 0.75rem /* 12px */;
  }
  .lg\:pl-\[3\.9\%\] {
    padding-left: 3.9%;
  }
  .lg\:gap-x-\[77px\] {
    column-gap: 77px;
  }
  .lg\:pr-\[5\%\] {
    padding-right: 5%;
  }

  .lg\:pt-\[61px\] {
    padding-top: 61px;
  }
  .lg\:mb-\[10px\] {
    margin-bottom: 10px;
  }

  .lg\:pb-4 {
    padding-bottom: 1rem /* 16px */;
  }
  .lg\:mt-7 {
    margin-top: 1.75rem /* 28px */;
  }
  .lg\:w-\[20\.3\%\] {
    width: 20.3%;
  }
  .lg\:w-\[51\.3\%\] {
    width: 51.3%;
  }
  .lg\:mb-5 {
    margin-bottom: 1.25rem /* 20px */;
  }
  .lg\:justify-between {
    justify-content: space-between;
  }
  .lg\:justify-end {
    justify-content: flex-end;
  }
  .lg\:h-4\/5 {
    height: 80%;
  }
  .lg\:w-\[28\.4\%\] {
    width: 28.4%;
  }
  .lg\:border-l {
    border-left-width: 1px;
  }
  .lg\:border-t-0 {
    border-top-width: 0px;
  }
  .lg\:h-\[270px\] {
    height: 270px;
  }
  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .lg\:font-bold {
    font-weight: 700;
  }
  .lg\:mt-20 {
    margin-top: 5rem /* 80px */;
  }
  .lg\:mt-28 {
    margin-top: 7rem /* 112px */;
  }
  .lg\:h-\[25rem\] {
    height: 25rem /* 400px */;
  }
  .lg\:h-\[24rem\] {
    height: 24rem /* 384px */;
  }
  .lg\:w-\[90\%\] {
    width: 90%;
  }
  .lg\:w-\[701px\] {
    width: 701px;
  }
  .lg\:w-\[231px\] {
    width: 231px;
  }
  .lg\:px-7 {
    padding-left: 1.75rem /* 28px */;
    padding-right: 1.75rem /* 28px */;
  }
  .lg\:h-\[8vh\] {
    height: 8vh;
  }
  .lg\:bg-transparent {
    background-color: transparent;
  }
  .lg\:rounded-xl {
    border-radius: 0.75rem /* 12px */;
  }
  .lg\:mt-1 {
    margin-top: 0.25rem /* 4px */;
  }

  .lg\:w-\[80\%\] {
    width: 80%;
  }
  .lg\:h-\[35vw\] {
    height: 35vw;
  }
  .lg\:w-\[15\%\] {
    width: 15%;
  }
  .lg\:w-\[25\%\] {
    width: 25%;
  }
  .lg\:block {
    display: block;
  }
  .lg\:w-\[18\%\] {
    width: 18%;
  }
  .lg\:mt-0 {
    margin-top: 0px;
  }
  .lg\:w-2\/3 {
    width: 66.666667%;
  }
  .lg\:mt-32 {
    margin-top: 8rem /* 128px */;
  }
  .lg\:pl-\[50px\] {
    padding-left: 50px;
  }
  .lg\:pr-\[39px\] {
    padding-right: 39px;
  }
  .lg\:pb-6 {
    padding-bottom: 1.5rem /* 24px */;
  }
  .lg\:left-24 {
    left: 6rem /* 96px */;
  }
  .lg\:top-0 {
    top: 0px;
  }
  .lg\:bottom-0 {
    bottom: 0px;
  }
  .lg\:pt-3 {
    padding-top: 0.75rem /* 12px */;
  }
  .lg\:w-1\/3 {
    width: 33.333333%;
  }
  .lg\:gap-x-3 {
    column-gap: 0.75rem /* 12px */;
  }
  .lg\:h-\[27\.5vw\] {
    height: 27.5vw;
  }
  .lg\:gap-y-1\.5 {
    row-gap: 0.375rem /* 6px */;
  }
  .lg\:w-\[1000px\] {
    width: 1000px;
  }
  .lg\:mt-3 {
    margin-top: 0.75rem /* 12px */;
  }
  .lg\:p-5 {
    padding: 1.25rem /* 20px */;
  }
  .lg\:py-9 {
    padding-top: 2.25rem /* 36px */;
    padding-bottom: 2.25rem /* 36px */;
  }
  .lg\:justify-start {
    justify-content: flex-start;
  }
  .lg\:text-left {
    text-align: left;
  }
  .lg\:px-6 {
    padding-left: 1.5rem /* 24px */;
    padding-right: 1.5rem /* 24px */;
  }
  .lg\:pb-0 {
    padding-bottom: 0px;
  }
  .lg\:items-start {
    align-items: flex-start;
  }
  .lg\:pr-\[8\.1\%\] {
    padding-right: 8.1%;
  }
  .lg\:pr-\[3\%\] {
    padding-right: 3%;
  }
  .lg\:h-\[450px\] {
    height: 450px;
  }
  .lg\:pl-\[2\.7\%\] {
    padding-left: 2.7%;
  }
  .lg\:mx-20 {
    margin-left: 5rem /* 80px */;
    margin-right: 5rem /* 80px */;
  }
  .lg\:px-20 {
    padding-left: 5rem /* 80px */;
    padding-right: 5rem /* 80px */;
  }
  .lg\:h-\[43vw\] {
    height: 43vw;
  }
  .lg\:mr-20 {
    margin-right: 5rem /* 80px */;
  }
  .lg\:text-\[1\.1vw\] {
    font-size: 1.1vw;
  }
  .lg\:w-9 {
    width: 2.25rem /* 36px */;
  }
  .lg\:w-6 {
    width: 1.5rem /* 24px */;
  }
  .lg\:w-\[107px\] {
    width: 107px;
  }
  .lg\:h-\[46px\] {
    height: 46px;
  }
  .lg\:absolute {
    position: absolute;
  }
  .lg\:w-\[39\.5\%\] {
    width: 39.5%;
  }
  .lg\:w-\[59\%\] {
    width: 59%;
  }
  .lg\:h-\[191px\] {
    height: 191px;
  }
  .lg\:w-\[27\.1vw\] {
    width: 27.1vw;
  }
  .lg\:h-\[32vw\] {
    height: 32vw;
  }
  .lg\:w-\[93\.8\%\] {
    width: 93.8%;
  }
  .lg\:max-h-\[398px\] {
    max-height: 398px;
  }
  .lg\:w-\[6\.2\%\] {
    width: 6.2%;
  }
  .lg\:h-16 {
    height: 4rem /* 64px */;
  }
  .lg\:w-full {
    width: 100%;
  }
  .lg\:w-\[62\%\] {
    width: 62%;
  }
  .lg\:w-\[35\%\] {
    width: 35%;
  }
  .lg\:w-\[905px\] {
    width: 905px;
  }
  .lg\:aspect-auto {
    aspect-ratio: auto;
  }
  .lg\:w-fit {
    width: fit-content;
  }
  .lg\:mx-14 {
    margin-left: 3.5rem /* 56px */;
    margin-right: 3.5rem /* 56px */;
  }
  .lg\:mb-0 {
    margin-bottom: 0px;
  }
  .lg\:mx-2 {
    margin-left: 0.5rem /* 8px */;
    margin-right: 0.5rem /* 8px */;
  }

  .lg\:bottom-\[50px\] {
    bottom: 50px;
  }
  .lg\:flex-row {
    flex-direction: row;
  }
  .lg\:left-auto {
    left: auto;
  }

  .lg\:h-\[160px\] {
    height: 160px;
  }
  .lg\:my-14 {
    margin-top: 3.5rem /* 56px */;
    margin-bottom: 3.5rem /* 56px */;
  }
  .lg\:mr-6 {
    margin-right: 1.5rem /* 24px */;
  }
  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }
  .lg\:text-3xl {
    font-size: 1.875rem /* 30px */;
    line-height: 2.25rem /* 36px */;
  }
  .lg\:bottom-\[-180px\] {
    bottom: -180px;
  }
  .lg\:mb-\[180px\] {
    margin-bottom: 180px;
  }
  .lg\:pt-\[27px\] {
    padding-top: 27px;
  }
  .lg\:pl-\[24px\] {
    padding-left: 24px;
  }
  .lg\:pr-\[40px\] {
    padding-right: 40px;
  }
  .lg\:w-\[470px\] {
    width: 470px;
  }
  .lg\:justify-center {
    justify-content: center;
  }
  .lg\:flex-row-reverse {
    flex-direction: row-reverse;
  }
  .lg\:text-4xl {
    font-size: 2.25rem /* 36px */;
    line-height: 2.5rem /* 40px */;
  }
  .lg\:pl-\[33px\] {
    padding-left: 33px;
  }
  .lg\:pr-\[24px\] {
    padding-right: 24px;
  }
  .lg\:flex-none {
    flex: none;
  }
  .lg\:w-1\/3 {
    width: 33.333333%;
  }
  .lg\:ml-8 {
    margin-left: 2rem /* 32px */;
  }
  .lg\:aspect-\[1087\/567\] {
    aspect-ratio: 1087 / 567;
  }
  .lg\:h-\[96\.25\%\] {
    height: 96.25%;
  }
  .lg\:aspect-\[593\/1209\] {
    aspect-ratio: 593 / 1209;
  }

  .lg\:aspect-video {
    aspect-ratio: 16 / 9;
  }

  .lg\:aspect-\[1\.9\] {
    aspect-ratio: 1.9;
  }
  .lg\:pb-\[80px\] {
    padding-bottom: 80px;
  }

  .lg\:p-8 {
    padding: 2rem /* 32px */;
  }
  .lg\:mx-4 {
    margin-left: 1rem /* 16px */;
    margin-right: 1rem /* 16px */;
  }
  .lg\:order-2 {
    order: 2;
  }
  .lg\:block {
    display: block;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:order-1 {
    order: 1;
  }

  .lg\:h-\[50vh\] {
    height: 50vh;
  }
  .lg\:ml-52 {
    margin-left: 13rem /* 208px */;
  }
  .lg\:mr-10 {
    margin-right: 2.5rem /* 40px */;
  }
  .lg\:items-stretch {
    align-items: stretch;
  }
  .lg\:px-14 {
    padding-left: 3.5rem /* 56px */;
    padding-right: 3.5rem /* 56px */;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }
  .lg\:border-b-0 {
    border-bottom-width: 0px;
  }
  .lg\:pb-0 {
    padding-bottom: 0px;
  }
  .lg\:text-center {
    text-align: center;
  }
  .lg\:w-3\/4 {
    width: 75%;
  }
  .lg\:h-\[13rem\] {
    height: 13rem;
  }
  .lg\:w-\[17\.2rem\] {
    width: 17.2rem;
  }

  .lg\:h-\[250px\] {
    height: 250px;
  }

  .lg\:bottom-\[320px\] {
    bottom: 320px;
  }
  .lg\:h-\[72vh\] {
    height: 72vh;
  }

  .lg\:h-\[70vh\] {
    height: 70vh;
  }

  .lg\:pb-2 {
    padding-bottom: 0.5rem /* 16px */;
  }
  .lg\:p-1 {
    padding: 0.25rem /* 4px */;
  }
  .md\:w-\[239px\] {
    width: 239px;
  }

  .lg\:py-\[\.4ch\] {
    padding-top: 0.4ch;
    padding-bottom: 0.4ch;
  }
  .lg\:text-base {
    font-size: 1rem /* 16px */;
    line-height: 1.5rem /* 24px */;
  }
  .lg\:my-16 {
    margin-top: 4rem /* 64px */;
    margin-bottom: 4rem /* 64px */;
  }
  .lg\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  }
  .lg\:max-h-\[523px\] {
    max-height: 523px;
  }
  .lg\:h-\[523px\] {
    height: 523px;
  }
  .lg\:h-\[544px\] {
    height: 544px;
  }
  .lg\:w-\[905px\] {
    width: 905px;
  }
  .lg\:text-\[28px\] {
    font-size: 28px;
  }
  .lg\:w-14 {
    width: 3.5rem /* 56px */;
  }
  .lg\:h-14 {
    height: 3.5rem /* 56px */;
  }
  .lg\:mr-40 {
    margin-right: 10rem /* 160px */;
  }
  .lg\:mt-4 {
    margin-top: 1rem /* 16px */;
  }
  .lg\:h-\[180px\] {
    height: 180px;
  }
  .lg\:text-xl {
    font-size: 1.25rem /* 20px */;
    line-height: 1.75rem /* 28px */;
  }
  .lg\:py-8 {
    padding-top: 2rem /* 32px */;
    padding-bottom: 2rem /* 32px */;
  }
  .lg\:mb-\[\.25ch\] {
    margin-bottom: 0.25ch;
  }
  .lg\:py-\[\.4ch\] {
    padding-top: 0.4ch;
    padding-bottom: 0.4ch;
  }
  .lg\:w-\[28\%\] {
    width: 28%;
  }
  .lg\:w-\[72\%\] {
    width: 72%;
  }
  .lg\:h-full {
    height: 100%;
  }
  .lg\:w-\[62\%\] {
    width: 62%;
  }
  .lg\:-right-20 {
    right: -5rem /* -80px */;
  }
  .lg\:w-\[7\.5vw\] {
    width: 7.5vw;
  }
  .lg\:w-\[8\.125vw\] {
    width: 8.125vw;
  }
  .lg\:max-w-\[80\%\] {
    max-width: 80%;
  }
  .lg\:py-14 {
    padding-top: 3.5rem /* 56px */;
    padding-bottom: 3.5rem /* 56px */;
  }
  .lg\:px-14 {
    padding-left: 3.5rem /* 56px */;
    padding-right: 3.5rem /* 56px */;
  }
  .lg\:h-\[75vh\] {
    height: 75vh;
  }
  .lg\:py-10 {
    padding-top: 2.5rem /* 40px */;
    padding-bottom: 2.5rem /* 40px */;
  }
  .lg\:px-16 {
    padding-left: 4rem /* 64px */;
    padding-right: 4rem /* 64px */;
  }
  .lg\:w-\[265px\] {
    width: 265px;
  }
  .lg\:h-\[240px\] {
    height: 240px;
  }
  .lg\:w-\[305px\] {
    width: 305px;
  }
  .lg\:h-\[288px\] {
    height: 288px;
  }
  .lg\:h-\[200px\] {
    height: 200px;
  }
  .lg\:h-\[182px\] {
    height: 182px;
  }
  .lg\:text-\[1\.1vw\] {
    font-size: 1.1vw;
  }
  .lg\:left-auto {
    left: auto;
  }
  .lg\:py-3 {
    padding-top: 0.75rem /* 12px */;
    padding-bottom: 0.75rem /* 12px */;
  }
  .lg\:py-5 {
    padding-top: 1.25rem /* 20px */;
    padding-bottom: 1.25rem /* 20px */;
  }
  .lg\:text-base {
    font-size: 1rem /* 16px */;
    line-height: 1.5rem /* 24px */;
  }
  .lg\:text-sm {
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
  }
  .lg\:text-\[1\.2vw\] {
    font-size: 1.2vw;
  }
  .lg\:w-\[70vw\] {
    width: 70vw;
  }
  .lg\:h-\[50vw\] {
    height: 50vw;
  }
  .lg\:right-9 {
    right: 2.25rem /* 36px */;
  }
  .lg\:h-\[91\.25vh\] {
    height: 91.25vh;
  }
  .lg\:w-\[88\.5\%\] {
    width: 88.5%;
  }
  .lg\:pl-\[1vw\] {
    padding-left: 1vw;
  }
  .lg\:aspect-\[1699\/191\] {
    aspect-ratio: 1699 / 191;
  }
  .lg\:text-2xl {
    font-size: 1.5rem /* 24px */;
    line-height: 2rem /* 32px */;
  }
  .lg\:w-\[21\%\] {
    width: 21%;
  }
  .lg\:flex {
    display: flex;
  }
  .lg\:w-\[64\%\] {
    width: 64%;
  }
  .lg\:w-\[34\.9\%\] {
    width: 34.9%;
  }
  .lg\:aspect-\[1699\/120\] {
    aspect-ratio: 1699 / 120;
  }
  .lg\:px-2 {
    padding-left: 0.5rem /* 8px */;
    padding-right: 0.5rem /* 8px */;
  }
  .lg\:w-\[33\%\] {
    width: 33%;
  }
  .lg\:h-\[90px\] {
    height: 90px;
  }
  .lg\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .lg\:h-20 {
    height: 5rem /* 80px */;
  }
  .lg\:h-\[16rem\] {
    height: 16rem /* 288px */;
  }
  .lg\:pb-8 {
    padding-bottom: 2rem /* 32px */;
  }
  .lg\:py-4 {
    padding-top: 1rem /* 16px */;
    padding-bottom: 1rem /* 16px */;
  }
  .lg\:mr-6 {
    margin-right: 1.5rem /* 24px */;
  }
  .lg\:w-3\/4 {
    width: 75%;
  }
  .lg\:h-11 {
    height: 2.75rem /* 44px */;
  }
  .lg\:w-11 {
    width: 2.75rem /* 44px */;
  }
  .lg\:h-\[110px\] {
    height: 110px;
  }
  .lg\:top-1\/2 {
    top: 50%;
  }

  .lg\:mx-10 {
    margin-left: 2.5rem /* 40px */;
    margin-right: 2.5rem /* 40px */;
  }

  .lg\:aspect-\[2\.177\] {
    aspect-ratio: 2.177;
  }

  .lg\:mt-8 {
    margin-top: 2rem /* 32px */;
  }
  .lg\:px-5 {
    padding-left: 1.25rem /* 20px */;
    padding-right: 1.25rem /* 20px */;
  }
  .lg\:w-\[57\%\] {
    width: 57%;
  }
  .lg\:w-\[255px\] {
    width: 255px;
  }
  .lg\:w-\[215px\] {
    width: 215px;
  }
  .lg\:text-\[25px\] {
    font-size: 25px;
  }
  .lg\:w-3\/5 {
    width: 60%;
  }
  .lg\:w-\[39\%\] {
    width: 39%;
  }
  .lg\:h-auto {
    height: auto;
  }
  .lg\:w-\[70\%\] {
    width: 70%;
  }
  .lg\:w-\[29\%\] {
    width: 29%;
  }
  .lg\:h-\[190px\] {
    height: 190px;
  }
  .lg\:max-h-\[96\.25\%\] {
    max-height: 96.25%;
  }
  .lg\:max-h-\[900px\] {
    max-height: 900px;
  }

  .lg\:pb-3 {
    padding-bottom: 0.75rem /* 12px */;
  }
  .lg\:ml-0 {
    margin-left: 0px;
  }
}

@media (min-width: 1280px) {
  .xl\:max-h-\[1100px\] {
    max-height: 1100px;
  }
  .xl\:pl-\[6\%\] {
    padding-left: 6%;
  }
  .xl\:pr-\[6\%\] {
    padding-right: 6%;
  }
  .xl\:pt-\[30px\] {
    padding-top: 30px;
  }
  .xl\:h-\[850px\] {
    height: 850px;
  }
  .xl\:rounded-\[20px\] {
    border-radius: 20px;
  }
  .xl\:justify-between {
    justify-content: space-between;
  }
  .xl\:pt-\[154px\] {
    padding-top: 154px;
  }
  .xl\:h-\[800px\] {
    height: 800px;
  }

  .xl\:w-1\/3 {
    width: 33.333333%;
  }

  .xl\:w-\[77\.8125\%\] {
    width: 77.8125%;
  }
  .xl\:text-\[50px\] {
    font-size: 50px;
  }
  .xl\:w-\[515px\] {
    width: 515px;
  }

  .xl\:w-\[29vw\] {
    width: 29vw;
  }
  .xl\:h-\[16vw\] {
    height: 16vw;
  }
  .xl\:ml-\[4\.4270\%\] {
    margin-left: 4.427%;
  }
  .xl\:px-\[14\.959\%\] {
    padding-left: 14.959%;
    padding-right: 14.959%;
  }
  .xl\:w-\[1212px\] {
    width: 1212px;
  }
  .xl\:rounded-\[10px\] {
    border-radius: 10px;
  }
  .xl\:w-\[602px\] {
    width: 602px;
  }
  .xl\:w-\[485px\] {
    width: 485px;
  }
  .xl\:px-\[1\.5625vw\] {
    padding-left: 1.5625vw;
    padding-right: 1.5625vw;
  }
  .xl\:ml-5 {
    margin-left: 1.25rem /* 20px */;
  }
  .xl\:mt-5 {
    margin-top: 1.25rem /* 20px */;
  }
  .xl\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .xl\:mb-4 {
    margin-bottom: 1rem /* 16px */;
  }
  .xl\:pt-\[1\.2vw\] {
    padding-top: 1.2vw;
  }

  .xl\:mt-6 {
    margin-top: 1.5rem /* 24px */;
  }
  .xl\:mt-\[-10vh\] {
    margin-top: -10vh;
  }
  .xl\:h-\[20rem\] {
    height: 20rem /* 320px */;
  }
  .xl\:w-\[1178px\] {
    width: 1178px;
  }
  .xl\:w-auto {
    width: auto;
  }
  .xl\:h-\[18rem\] {
    height: 18rem /* 288px */;
  }
  .xl\:w-\[80vw\] {
    width: 80vw;
  }
  .xl\:h-\[45rem\] {
    height: 45rem /* 800px */;
  }
  .xl\:ml-0 {
    margin-left: 0px;
  }
  .xl\:pl-0 {
    padding-left: 0px;
  }
  .xl\:w-\[31\%\] {
    width: 31%;
  }
  .xl\:mt-0 {
    margin-top: 0px;
  }
  .xl\:h-\[10vh\] {
    height: 10vh;
  }
  .xl\:ml-10 {
    margin-left: 2.5rem /* 40px */;
  }
  .xl\:w-\[17\%\] {
    width: 17%;
  }
  .xl\:h-\[10rem\] {
    height: 10rem /* 160px */;
  }
  .xl\:h-\[35vw\] {
    height: 35vw;
  }

  .xl\:h-10 {
    height: 2.5rem /* 40px */;
  }
  .xl\:w-\[210px\] {
    width: 210px;
  }
  .xl\:w-\[82\%\] {
    width: 82%;
  }

  .xl\:h-\[118px\] {
    height: 118px;
  }

  .xl\:w-10 {
    width: 2.5rem /* 40px */;
  }
  .xl\:py-\[40\.5px\] {
    padding-top: 40.5px;
    padding-bottom: 40.5px;
  }
  .xl\:w-28 {
    width: 7rem /* 112px */;
  }
  .xl\:bg-transparent {
    background-color: transparent;
  }
  .xl\:pl-6 {
    padding-left: 1.5rem /* 24px */;
  }
  .xl\:pr-6 {
    padding-right: 1.5rem /* 24px */;
  }
  .xl\:text-center {
    text-align: center;
  }
  .xl\:w-auto {
    width: auto;
  }
  .xl\:text-\[\.9vw\] {
    font-size: 0.9vw;
  }
  .xl\:w-7 {
    width: 1.75rem /* 28px */;
  }
  .xl\:gap-x-9 {
    column-gap: 2.25rem /* 36px */;
  }
  .xl\:gap-x-0 {
    column-gap: 0px;
  }
  .xl\:h-11 {
    height: 2.75rem /* 44px */;
  }
  .xl\:h-12 {
    height: 3rem /* 48px */;
  }
  .xl\:relative {
    position: relative;
  }
  .xl\:w-\[130px\] {
    width: 130px;
  }
  .xl\:h-\[57px\] {
    height: 57px;
  }
  .xl\:static {
    position: static;
  }
  .xl\:w-\[89\%\] {
    width: 89%;
  }
  .xl\:gap-x-0 {
    column-gap: 0px;
  }
  .xl\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:h-\[41vw\] {
    height: 41vw;
  }
  .xl\:h-\[636px\] {
    height: 636px;
  }
  .xl\:h-\[676px\] {
    height: 676px;
  }

  .xl\:gap-x-8 {
    column-gap: 2rem /* 32px */;
  }
  .xl\:mr-20 {
    margin-right: 5rem /* 80px */;
  }
  .xl\:h-\[229px\] {
    height: 229px;
  }
  .xl\:rounded-xl {
    border-radius: 0.75rem /* 12px */;
  }
  .xl\:w-2\/5 {
    width: 45%;
  }
  .xl\:w-full {
    width: 100%;
  }
  .xl\:w-\[700px\] {
    width: 700px;
  }
  .xl\:w-\[500px\] {
    width: 500px;
  }
  .xl\:w-\[300px\] {
    width: 300px;
  }
  .xl\:w-\[105px\] {
    width: 105px;
  }
  .xl\:mt-\[270px\] {
    margin-top: 270px;
  }
  .xl\:h-\[56px\] {
    height: 56px;
  }
  .xl\:w-\[88px\] {
    width: 88px;
  }
  .xl\:h-\[50\.44px\] {
    height: 50.44px;
  }
  .xl\:mt-10 {
    margin-top: 2.5rem /* 40px */;
  }
  .xl\:px-14 {
    padding-left: 3.5rem /* 56px */;
    padding-right: 3.5rem /* 56px */;
  }

  .xl\:top-\[62px\] {
    top: 62px;
  }
  .xl\:max-h-\[16vw\] {
    max-height: 16vw;
  }
  .xl\:mt-5 {
    margin-top: 1.25rem /* 20px */;
  }
  .xl\:h-\[300px\] {
    height: 300px;
  }
  .xl\:justify-end {
    justify-content: flex-end;
  }
  .xl\:py-\[3\.7vw\] {
    padding-top: 3.7vw;
    padding-bottom: 3.7vw;
  }
  .xl\:border-l-2 {
    border-left-width: 2px;
  }
  .xl\:w-\[72\.5\%\] {
    width: 72.5%;
  }
  .xl\:h-\[56px\] {
    height: 56px;
  }
  .xl\:justify-start {
    justify-content: flex-start;
  }
  .xl\:py-\[\.6ch\] {
    padding-top: 0.6ch;
    padding-bottom: 0.6ch;
  }
  .xl\:gap-y-3 {
    row-gap: 0.75rem /* 12px */;
  }
  .xl\:text-xs {
    font-size: 0.75rem /* 12px */;
    line-height: 1rem /* 16px */;
  }
  .xl\:text-sm {
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
  }
  .xl\:text-2xl {
    font-size: 1.5rem /* 24px */;
    line-height: 2rem /* 32px */;
  }
  .xl\:p-2 {
    padding: 0.5rem /* 8px */;
  }
  .xl\:py-12 {
    padding-top: 3rem /* 48px */;
    padding-bottom: 3rem /* 48px */;
  }
  .xl\:h-\[278px\] {
    height: 278px;
  }
  .xl\:py-2 {
    padding-top: 0.5rem /* 8px */;
    padding-bottom: 0.5rem /* 8px */;
  }
  .xl\:w-\[88px\] {
    width: 88px;
  }
  .xl\:h-\[50\.44px\] {
    height: 50.44px;
  }
  .xl\:text-3xl {
    font-size: 1.875rem /* 30px */;
    line-height: 2.25rem /* 36px */;
  }
  .xl\:text-4xl {
    font-size: 2.25rem /* 36px */;
    line-height: 2.5rem /* 40px */;
  }
  .xl\:text-lg {
    font-size: 1.125rem /* 18px */;
    line-height: 1.75rem /* 28px */;
  }
  .xl\:px-\[4\%\] {
    padding-left: 4%;
    padding-right: 4%;
  }
  .xl\:text-left {
    text-align: left;
  }
  .xl\:px-4 {
    padding-left: 1rem /* 16px */;
    padding-right: 1rem /* 16px */;
  }
  .xl\:w-\[57\%\] {
    width: 57%;
  }
  .xl\:w-\[115px\] {
    width: 115px;
  }

  .xl\:text-xl {
    font-size: 1.25rem /* 20px */;
    line-height: 1.75rem /* 28px */;
  }
  .xl\:w-\[804px\] {
    width: 804px;
  }
  .xl\:w-\[45\%\] {
    width: 45%;
  }
  .xl\:text-base {
    font-size: 1rem /* 16px */;
    line-height: 1.5rem /* 24px */;
  }

  .xl\:py-10 {
    padding-top: 2.5rem /* 40px */;
    padding-bottom: 2.5rem /* 40px */;
  }
  .xl\:py-\[\.6ch\] {
    padding-top: 0.6ch;
    padding-bottom: 0.6ch;
  }
  .xl\:mt-4 {
    margin-top: 1rem /* 16px */;
  }
  .xl\:w-\[43\%\] {
    width: 43%;
  }
  .xl\:w-\[32\%\] {
    width: 32%;
  }
  .xl\:pb-20 {
    padding-bottom: 5rem /* 80px */;
  }
  .xl\:w-\[260px\] {
    width: 260px;
  }

  .xl\:w-\[48\.2\%\] {
    width: 48.2%;
  }
  .xl\:w-\[682px\] {
    width: 682px;
  }
  .xl\:h-\[414px\] {
    height: 414px;
  }
  .xl\:px-\[104px\] {
    padding-left: 104px;
    padding-right: 104px;
  }
  .xl\:pt-\[63px\] {
    padding-top: 63px;
  }
  .xl\:p-0 {
    padding: 0px;
  }
  .xl\:mb-\[30px\] {
    margin-bottom: 30px;
  }
  .xl\:m-6 {
    margin: 1.5rem;
  }
  .xl\:mt-40 {
    margin-top: 9rem;
  }
  .xl\:mt-20 {
    margin-top: 5rem;
  }
  .xl\:w-4\/12 {
    width: 33.333333%;
  }
  .xl\:translate-x-\[3vw\] {
    --tw-translate-x: 3vw;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y));
  }
  .xl\:mx-2 {
    margin-left: 0.5rem /* 8px */;
    margin-right: 0.5rem /* 8px */;
  }
  .xl\:mt-\[9px\] {
    margin-top: 9px;
  }
  .xl\:pt-0 {
    padding-top: 0px;
  }
  .xl\:pb-0 {
    padding-bottom: 0px;
  }
  .xl\:w-1\/4 {
    width: 25%;
  }
  .xl\:mt-\[115px\] {
    margin-top: 115px;
  }
  .xl\:text-\[28px\] {
    font-size: 28px;
  }

  .xl\:w-1\/2 {
    width: 50%;
  }
  .xl\:w-3\/4 {
    width: 75%;
  }

  .xl\:w-1\/5 {
    width: 20%;
  }
  .xl\:px-2 {
    padding-left: 0.5rem /* 8px */;
    padding-right: 0.5rem /* 8px */;
  }
  .xl\:text-\[22px\] {
    font-size: 22px;
  }

  .xl\:pl-20 {
    padding-left: 5rem;
  }

  .xl\:pl-10 {
    padding-left: 2.5rem;
  }

  .xl\:w-56 {
    width: 14rem;
  }

  .xl\:w-\[18\.7rem\] {
    width: 18.7rem;
  }

  .xl\:h-\[80vh\] {
    height: 80vh;
  }
  .xl\:h-full {
    height: 100%;
  }
  .xl\:max-h-\[80vh\] {
    max-height: 80vh;
  }
  .xl\:text-\[min\(1vw\2c 16px\)\] {
    font-size: min(1vw, 16px);
  }
  .xl\:h-\[68vh\] {
    max-height: 68vh;
  }

  .xl\:bottom-\[330px\] {
    bottom: 330px;
  }
  .xl\:bottom-\[350px\] {
    bottom: 350px;
  }

  .xl\:h-\[250px\] {
    height: 250px;
  }

  .xl\:h-16 {
    height: 4rem /* 64px */;
  }
  .xl\:w-\[1056px\] {
    width: 1056px;
  }
  .xl\:h-8 {
    height: 2rem /* 32px */;
  }
  .xl\:w-8 {
    width: 2rem /* 32px */;
  }
  .xl\:mt-\[0\.9vw\] {
    margin-top: 0.9vw;
  }

  .xl\:m-4 {
    margin: 1rem /* 16px */;
  }

  .xl\:bottom-\[70px\] {
    bottom: 70px;
  }

  .xl\:h-\[80px\] {
    height: 80px;
  }

  .xl\:mt-\[-80px\] {
    margin-top: -80px;
  }

  .xl\:aspect-video {
    aspect-ratio: 16 / 9;
  }

  .xl\:pb-6 {
    padding-bottom: 1.5rem /* 24px */;
  }
  .xl\:mx-4 {
    margin-left: 1rem /* 16px */;
    margin-right: 1rem /* 16px */;
  }

  .xl\:ml-3 {
    margin-left: 0.75rem /* 12px */;
  }
  .xl\:py-4 {
    padding-top: 1rem /* 16px */;
    padding-bottom: 1rem /* 16px */;
  }

  .xl\:w-12 {
    width: 3rem /* 48px */;
  }

  .xl\:px-5 {
    padding-left: 1.25rem /* 20px */;
    padding-right: 1.25rem /* 20px */;
  }
  .xl\:w-3\/5 {
    width: 60%;
  }
  .xl\:py-\[\.6ch\] {
    padding-top: 0.6ch;
    padding-bottom: 0.6ch;
  }
  .xl\:ml-44 {
    margin-left: 11rem /* 176px */;
  }
  .xl\:ml-4 {
    margin-left: 1rem /* 16px */;
  }
  .xl\:ml-8 {
    margin-left: 2rem /* 32px */;
  }
  .xl\:ml-14 {
    margin-left: 3.5rem /* 56px */;
  }
  .xl\:px-24 {
    padding-left: 6rem /* 96px */;
    padding-right: 6rem /* 96px */;
  }
  .xl\:mt-11 {
    margin-top: 2.75rem /* 44px */;
  }
  .xl\:mt-2\.5 {
    margin-top: 0.625rem /* 10px */;
  }
  .xl\:mt-9 {
    margin-top: 2.25rem /* 36px */;
  }
  .xl\:ml-24 {
    margin-left: 6rem /* 96px */;
  }
  .xl\:h-\[138px\] {
    height: 138px;
  }
  .xl\:h-\[596px\] {
    height: 596px;
  }
  .xl\:h-\[27\.5vw\] {
    height: 27.5vw;
  }
  .xl\:py-4 {
    padding-top: 1rem /* 16px */;
    padding-bottom: 1rem /* 16px */;
  }
  .xl\:mb-\[16px\] {
    margin-bottom: 16px;
  }
  .xl\:pt-\[3\%\] {
    padding-top: 3%;
  }
  .xl\:px-14 {
    padding-left: 3.5rem /* 56px */;
    padding-right: 3.5rem /* 56px */;
  }
  .xl\:w-2\/5 {
    width: 40%;
  }
  .xl\:block {
    display: block;
  }
  .xl\:mr-14 {
    margin-right: 3.5rem /* 56px */;
  }
  .xl\:h-\[85vh\] {
    height: 85vh;
  }
  .xl\:py-12 {
    padding-top: 3rem /* 48px */;
    padding-bottom: 3rem /* 48px */;
  }
  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .xl\:px-20 {
    padding-left: 5rem /* 80px */;
    padding-right: 5rem /* 80px */;
  }
  .xl\:flex-row-reverse {
    flex-direction: row-reverse;
  }
  .xl\:flex-row {
    flex-direction: row;
  }
  .xl\:pb-0 {
    padding-bottom: 0px;
  }
  .xl\:translate-x-\[3vw\] {
    --tw-translate-x: 3vw;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y));
  }
  .xl\:leading-5 {
    line-height: 1.25rem /* 20px */;
  }
  .xl\:ml-7 {
    margin-left: 1.75rem /* 28px */;
  }
  .xl\:w-\[54vw\] {
    width: 54vw;
  }
  .xl\:pb-10 {
    padding-bottom: 2.5rem /* 40px */;
  }
  .xl\:pt-10 {
    padding-top: 2.5rem /* 40px */;
  }

  .xl\:w-\[14vw\] {
    width: 14vw;
  }
  .xl\:mr-14 {
    margin-right: 3.5rem /* 56px */;
  }
  .xl\:ml-14 {
    margin-left: 3.5rem /* 56px */;
  }
  .xl\:w-\[1000px\] {
    width: 1000px;
  }
  .xl\:w-\[75\%\] {
    width: 75%;
  }
  .xl\:h-\[18\.75vw\] {
    height: 18.75vw;
  }
  .xl\:h-auto {
    height: auto;
  }
  .xl\:w-\[29vw\] {
    width: 29vw;
  }

  .xl\:h-\[15\.8vw\] {
    height: 15.8vw;
  }
  .xl\:max-h-\[18\.5vw\] {
    max-height: 18.5vw;
  }
  .xl\:rounded-lg {
    border-radius: 0.5rem /* 8px */;
  }
  .xl\:mx-24 {
    margin-left: 6rem /* 96px */;
    margin-right: 6rem /* 96px */;
  }

  .xl\:h-\[17vw\] {
    height: 17vw;
  }
  .xl\:py-6 {
    padding-top: 1.5rem /* 24px */;
    padding-bottom: 1.5rem /* 24px */;
  }
  .xl\:text-\[1\.15vw\] {
    font-size: 1.15vw;
  }

  .xl\:h-\[7\.6vw\] {
    height: 7.6vw;
  }
  .xl\:h-\[7\.6vw\] {
    height: 7.6vw;
  }
  .xl\:text-\[1vw\] {
    font-size: 1vw;
  }
  .xl\:mt-28 {
    margin-top: 7rem /* 112px */;
  }

  .xl\:mt-40 {
    margin-top: 10rem /* 160px */;
  }
  .xl\:mt-16 {
    margin-top: 4rem /* 64px */;
  }
  .xl\:bottom-\[-140px\] {
    bottom: -140px;
  }
  .xl\:w-\[1038px\] {
    width: 1038px;
  }
  .xl\:w-\[1086px\] {
    width: 1086px;
  }
  .xl\:w-\[100px\] {
    width: 100px;
  }
  .xl\:h-\[100px\] {
    height: 100px;
  }
}

@media (min-width: 1536px) {
  .\32xl\:h-\[100px\] {
    height: 100px;
  }
  .\32xl\:aspect-\[21\/10\] {
    aspect-ratio: 21 / 10;
  }
  .\32xl\:pt-\[1\.8vw\] {
    padding-top: 1.8vw;
  }

  .\32xl\:pl-\[1\.5vw\] {
    padding-left: 1.5vw;
  }
  .\32xl\:w-\[1404px\] {
    width: 1404px;
  }
  .\32xl\:h-auto {
    height: auto;
  }
  .\32xl\:px-24 {
    padding-left: 6rem /* 96px */;
    padding-right: 6rem /* 96px */;
  }
  .\32xl\:h-\[46rem\] {
    height: 46rem /* 752px */;
  }

  .\32xl\:h-\[24rem\] {
    height: 24rem /* 384px */;
  }
  .\32xl\:py-\[47px\] {
    padding-top: 47px;
    padding-bottom: 47px;
  }
  .\32xl\:mt-32 {
    margin-top: 8rem /* 128px */;
  }
  .\32xl\:h-\[61px\] {
    height: 61px;
  }
  .\32xl\:w-\[140px\] {
    width: 140px;
  }
  .\32xl\:text-\[0\.94vw\] {
    font-size: 0.94vw;
  }
  .\32xl\:w-9 {
    width: 2.25rem /* 36px */;
  }
  .\32xl\:h-\[39vw\] {
    height: 39vw;
  }
  .\32xl\:h-\[286px\] {
    height: 286px;
  }
  .\32xl\:gap-x-12 {
    column-gap: 3rem /* 48px */;
  }
  .\32xl\:w-\[161px\] {
    width: 161px;
  }
  .\32xl\:h-\[70px\] {
    height: 70px;
  }
  .\32xl\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:w-\[36\%\] {
    width: 36%;
  }
  .\32xl\:mr-20 {
    margin-right: 5rem /* 80px */;
  }
  .\32xl\:ml-20 {
    margin-left: 5rem /* 80px */;
  }
  .\32xl\:text-lg {
    font-size: 1.125rem /* 18px */;
    line-height: 1.75rem /* 28px */;
  }
  .\32xl\:bottom-\[-90px\] {
    bottom: -90px;
  }

  .\32xl\:mb-\[130px\] {
    margin-bottom: 130px;
  }

  .\32xl\:ml-\[1\.6vw\] {
    margin-left: 1.1vw;
  }
  .\32xl\:gap-y-5 {
    row-gap: 1.25rem /* 20px */;
  }
  .\32xl\:h-\[16rem\] {
    height: 16rem /* 256px */;
  }

  .\32xl\:py-9 {
    padding-top: 2.25rem /* 36px */;
    padding-bottom: 2.25rem /* 36px */;
  }
  .\32xl\:w-\[21\.9rem\] {
    width: 21.9rem;
  }
  .\32xl\:h-\[82vh\] {
    height: 82vh;
  }

  .\32xl\:h-\[80vh\] {
    height: 80vh;
  }

  .\32xl\:w-\[58\%\] {
    width: 58%;
  }

  .\32xl\:text-base {
    font-size: 1rem /* 16px */;
    line-height: 1.5rem /* 24px */;
  }
  .\32xl\:text-\[50px\] {
    font-size: 50px;
  }
  .\32xl\:text-5xl {
    font-size: 3rem /* 48px */;
    line-height: 1;
  }
  .\32xl\:mr-24 {
    margin-right: 6rem /* 96px */;
  }
  .\32xl\:ml-24 {
    margin-left: 6rem /* 96px */;
  }
  .\32xl\:h-\[16rem\] {
    height: 16rem /* 256px */;
  }

  .\32xl\:text-xl {
    font-size: 1.25rem /* 20px */;
    line-height: 1.75rem /* 28px */;
  }

  .\32xl\:items-center {
    align-items: center;
  }

  .\32xl\:aspect-\[2\.2\] {
    aspect-ratio: 2.2;
  }

  .\32xl\:mt-10 {
    margin-top: 2.5rem /* 40px */;
  }
  .\32xl\:text-2xl {
    font-size: 1.5rem /* 24px */;
    line-height: 2rem /* 32px */;
  }
  .\32xl\:max-h-\[460px\] {
    max-height: 460px;
  }
  .\32xl\:text-sm {
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
  }
  .\32xl\:ml-\[3vw\] {
    margin-left: 3vw;
  }
  .\32xl\:w-\[380px\] {
    width: 380px;
  }
  .\32xl\:w-auto {
    width: auto;
  }
  .\32xl\:px-20 {
    padding-left: 5rem /* 80px */;
    padding-right: 5rem /* 80px */;
  }
  .\32xl\:px-\[22px\] {
    padding-left: 22px;
    padding-right: 22px;
  }
  .\32xl\:h-\[18rem\] {
    height: 18rem /* 288px */;
  }
  .\32xl\:pt-\[4\%\] {
    padding-top: 4%;
  }
  .\32xl\:mb-\[4px\] {
    margin-bottom: 4px;
  }
  .\32xl\:mb-\[20px\] {
    margin-bottom: 20px;
  }
  .\32xl\:pt-5 {
    padding-top: 1.25rem /* 20px */;
  }
  .\32xl\:p-5 {
    padding: 1.25rem /* 20px */;
  }
  .\32xl\:text-\[28px\] {
    font-size: 28px;
  }
  .\32xl\:py-14 {
    padding-top: 3.5rem /* 56px */;
    padding-bottom: 3.5rem /* 56px */;
  }
  .\32xl\:ml-8 {
    margin-left: 2rem /* 32px */;
  }
  .\32xl\:text-\[40px\] {
    font-size: 40px;
  }
  .\32xl\:h-\[200px\] {
    height: 200px;
  }
  .\32xl\:px-\[2vw\] {
    padding-left: 2vw;
    padding-right: 2vw;
  }
  .\32xl\:text-3xl {
    font-size: 1.875rem /* 30px */;
    line-height: 2.25rem /* 36px */;
  }
  .\32xl\:pb-2 {
    padding-bottom: 0.5rem /* 8px */;
  }

  .\32xl\:ml-40 {
    margin-left: 10rem /* 160px */;
  }
  .\32xl\:ml-52 {
    margin-left: 13rem /* 208px */;
  }
  .\32xl\:py-10 {
    padding-top: 2.5rem /* 40px */;
    padding-bottom: 2.5rem /* 40px */;
  }
  .\32xl\:px-32 {
    padding-left: 8rem /* 128px */;
    padding-right: 8rem /* 128px */;
  }
  .\32xl\:mt-\[-10vh\] {
    margin-top: -10vh;
  }
  .\32xl\:h-\[515px\] {
    height: 515px;
  }
  .\32xl\:ml-32 {
    margin-left: 8rem /* 128px */;
  }

  .\32xl\:w-\[375px\] {
    width: 375px;
  }
  .\32xl\:px-4 {
    padding-left: 1rem /* 16px */;
    padding-right: 1rem /* 16px */;
  }
  .\32xl\:ml-8 {
    margin-left: 2rem /* 32px */;
  }
  .\32xl\:h-14 {
    height: 3.5rem /* 56px */;
  }
  .\32xl\:w-\[333px\] {
    width: 333px;
  }
  .\32xl\:h-screen {
    height: 100vh;
  }
  .\32xl\:py-16 {
    padding-top: rem /* 64px */;
    padding-bottom: rem /* 64px */;
  }
  .\32xl\:px-28 {
    padding-left: 7rem /* 112px */;
    padding-right: 7rem /* 112px */;
  }
  .\32xl\:w-\[305px\] {
    width: 305px;
  }
  .\32xl\:h-\[277px\] {
    height: 277px;
  }
  .\32xl\:w-\[16vw\] {
    width: 16vw;
  }

  .\32xl\:h-\[18\.77vw\] {
    height: 18.77vw;
  }
  .\32xl\:w-\[33vw\] {
    width: 33vw;
  }
  .\32xl\:h-\[18\.85vw\] {
    height: 18.85vw;
  }

  .\32xl\:h-\[210px\] {
    height: 210px;
  }
  .\32xl\:h-32 {
    height: 8rem /* 128px */;
  }

  .\32xl\:mx-32 {
    margin-left: 8rem /* 128px */;
    margin-right: 8rem /* 128px */;
  }

  .\32xl\:w-\[34\.2vw\] {
    width: 34.2vw;
  }
  .\32xl\:h-\[230px\] {
    height: 230px;
  }
  .\32xl\:h-\[20\.1vw\] {
    height: 20.1vw;
  }
  .\32xl\:px-14 {
    padding-left: 3.5rem /* 56px */;
    padding-right: 3.5rem /* 56px */;
  }
  .\32xl\:text-\[1\.04vw\] {
    font-size: 1.04vw;
  }
  .\32xl\:h-\[8\.9vw\] {
    height: 8.9vw;
  }
  .\32xl\:text-\[0\.94vw\] {
    font-size: 0.94vw;
  }
  .\32xl\:mt-24 {
    margin-top: 6rem /* 96px */;
  }
  .\32xl\:px-\[1vw\] {
    padding-left: 1vw;
    padding-right: 1vw;
  }
  .\32xl\:pl-\[12\.8125\%\] {
    padding-left: 12.8125%;
  }
  .\32xl\:pl-\[12\.8125\%\] {
    padding-left: 12.8125%;
  }
  .\32xl\:h-\[380px\] {
    height: 380px;
  }
}
